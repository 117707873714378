import { IconButton, Tooltip } from "@mui/material";
import { ReactComponent as Back } from "../../assets/icons/back.svg";
import { useNavigate } from "react-router-dom";

const BackButton = ({ to = -1 }) => {
  const navigate = useNavigate();
  return (
    <Tooltip title="Back">
      <IconButton onClick={() => navigate(to)}>
        <Back />
      </IconButton>
    </Tooltip>
  );
};

export default BackButton;
