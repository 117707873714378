import React, { useState, useEffect } from 'react';
import { createSignedUrl } from '../../services/amplify/property';

/**
 * ImageComponent is a reusable component that fetches a signed URL for
 * an image stored in a private S3 bucket and displays it.
 * 
 * Props:
 * - `imageKey`: The key or path to the image in the S3 bucket.
 */
const ImageComponent = ({ imageKey, className, ...props }) => {
  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Function to fetch signed URL
  const fetchSignedUrl = async (key) => {
    try {
      setLoading(true);
      // Replace this with your signed URL fetching logic
      const response = await createSignedUrl(key); // Ensure you have a function to create signed URLs
      setImageUrl(response);
      setLoading(false);
    } catch (err) {
      setError('Error fetching the image');
      setLoading(false);
    }
  };

  useEffect(() => {
    if (imageKey) {
      fetchSignedUrl(imageKey);
    } else {
      setImageUrl('/plain-black.jpg'); // Default image
    }
  }, [imageKey]);

  return (
    <>
      {loading ? (
        <div className={className + " bg-gray-300 animate-pulse"}></div>
      ) : error ? (
        <p>{error}</p> // Display error if image fails to load
      ) : (
        <img src={imageUrl} alt="Fetched from S3" className={`${className} object-contain`} {...props} />
      )}
    </>
  );
};

export default ImageComponent;
