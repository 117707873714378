import { createSlice } from "@reduxjs/toolkit";

const propertySlice = createSlice({
    name: 'property',
    initialState: {
        properties: [],
        property: null,
        editProperty: null,
        extra: {
            state: null
        },
        catalogProducts: [],
        propertySheet: {
            propertyId: "",
            rooms: [],
            buyerTotal: 0,
        },
        selectedRoom: null,
    },
    reducers: {
        setProperties: (state, action) => {
            state.properties = action.payload;
        },
        setProperty: (state, action) => {
            state.property = action.payload;
        },
        setEditProperty: (state, action) => {
            state.editProperty = action.payload;
        },
        setCatalogProducts: (state, action) => {
            state.catalogProducts = action.payload;
        },
        setPropertySheet: (state, action) => {
            state.propertySheet = action.payload;
        },
        addRoom: (state, action) => {
            state.propertySheet.rooms.push(action.payload);
        },
        setSelectedRoom: (state, action) => {
            state.selectedRoom = action.payload;
        },
        addProduct: (state, action) => {
            const { name, product } = action.payload;
            const room = state.propertySheet.rooms.find(room => room.name === name);
            if (!room) {
                state.propertySheet.rooms.push({
                    name,
                    items: [product],
                    cost: product.price,
                    itemsCount: 1
                });
                state.propertySheet.buyerTotal = Number(state.propertySheet.buyerTotal) + Number(product.price)
                return;
            }

            if (!room.items) room.items = [];

            // Check if product already exists
            const existingProduct = room.items.find(p => p.id === product.id);
            if (existingProduct) {
                let newProduct = {
                    ...existingProduct,
                    quantity: existingProduct.quantity + 1,
                }

                room.items = room.items.map(p => p.id === newProduct.id ? newProduct : p);

                room.cost = Number(room.cost) + Number(product.price);
                room.itemsCount = room.itemsCount + 1;
                state.propertySheet.buyerTotal = Number(state.propertySheet.buyerTotal) + Number(product.price)
                return;
            }
            room.items.push(product);
            room.itemsCount = room.itemsCount ? room.itemsCount + 1 : 1;
            room.cost = room.cost ? room.cost + Number(product.price) : Number(product.price);
            state.propertySheet.buyerTotal = Number(state.propertySheet.buyerTotal) + Number(product.price);
        },
        addImage: (state, action) => {
            const { name, image } = action.payload;

            if (!state.propertySheet.rooms || !state.propertySheet.rooms.length > 0) return;

            if (!name || !image) return;
            if (!image.src) return;

            // get other room images and 1+ with id

            image.id = state.propertySheet.rooms.reduce((acc, room) => {
                if (!room.images || room.images.length === 0) return acc;
                return room.images.reduce((innerAcc, image) => {
                    const imageId = parseInt(image.id, 10); // Ensure id is a number
                    return Math.max(innerAcc, imageId);
                }, acc);
            }, 0) + 1;

            const room = state.propertySheet.rooms.find(room => room.name === name);
            if (!room) {
                state.propertySheet.rooms.push({
                    name,
                    images: [image]
                });
                return;
            }

            if (!room.images) room.images = [];
            room.images.push(image);
        },

        rearrangeImages: (state, action) => {
            const { name, images } = action.payload;
            if (!name || !images) return;

            const room = state.propertySheet.rooms.find(room => room.name === name);
            if (!room) return;

            room.images = images;
        },

        removeImage: (state, action) => {
            const { name, id } = action.payload;
            
            if (!state.propertySheet.rooms || !state.propertySheet.rooms.length > 0) return;
            if (!name || !id) return;

            const room = state.propertySheet.rooms.find(room => room.name === name);
            if (!room) return;

            if (!room.images) return;

            room.images = room.images.filter(image => image.id !== id);
        },

        removeProduct: (state, action) => {
            const { name, id } = action.payload;
            const room = state.propertySheet.rooms.find(room => room.name === name);
            if (!room) return;

            // if items is not defined, return
            if (!room.items) return;

            // if that product quantity is more than 1, reduce the quantity
            const product = room.items.find(product => product.id === id);
            if (product.quantity > 1) {
                product.quantity -= 1;
                room.cost = room.cost - Number(product.price);
                room.itemsCount = room.itemsCount - 1 || 0;
                state.propertySheet.buyerTotal = Number(state.propertySheet.buyerTotal) - Number(product.price);
                return;
            }

            // if quantity is 1, remove the product from the list
            room.items = room.items.filter(product => product.id !== id);
            room.cost = room.cost - Number(product.price);
            room.itemsCount = room.itemsCount - 1 || 0;
            state.propertySheet.buyerTotal = Number(state.propertySheet.buyerTotal) - Number(product.price);
        },
        setState: (state, action) => {
            state.extra = {
                state: action.payload
            }
        }
    },
});

export const {
    setProperties, setProperty, setEditProperty, setCatalogProducts,
    setPropertySheet, addRoom, addProduct, removeProduct, setSelectedRoom,
    setState,
    addImage,
    removeImage,
    rearrangeImages
} = propertySlice.actions;

export default propertySlice.reducer;


