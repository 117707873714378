import React from "react";
import { Navigate, Outlet, useRoutes } from "react-router-dom";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Layout from "./components/Layout";
import PropertyDetailsPage from "./pages/PropertyDetails";
import { config } from "./constants";
import { useSelector } from "react-redux";
import ProductCatalogPage from "./pages/ProductCatalog/ProductCatalogPage";

/** Amplify Configuration */
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import amplifyConfig from "./amplifyconfiguration.json";
import PropertySheetPage from "./pages/PropertySheet/PropertySheetPage";
import SheetDetailPage from "./pages/PropertySheet/Detail";
import PropertyUpdatePage from "./pages/PropertyUpdate/PropertyUpdatePage";
import PropertyLandingPage from "./pages/PropertyLandingPage";
import PropertyRoomDetailsPage from "./pages/PropertyLandingPage/PropertyRoomDetailsPage";
Amplify.configure(amplifyConfig);

const App = () => {
  const isLogged = useSelector(state => state.user.isLogged);
  const router = useRoutes([
    {
      path: config.PATH_URI.HOME,
      element: isLogged ? <Layout /> : <Navigate to={config.PATH_URI.LOGIN} />,
      children: [
        {
          path: config.PATH_URI.HOME,
          element: <Home />,
        },
        {
          path: config.PATH_URI.PROPERTY_CREATE,
          element: <PropertyDetailsPage />,
        },
        {
          path: config.PATH_URI.PROPERTY_UPDATE,
          element: <PropertyUpdatePage />,
        },
        {
          path: config.PATH_URI.PRODUCT_CATALOG,
          element: <ProductCatalogPage />,
        },
        {
          path: config.PATH_URI.PROPERTY_SHEET.MAIN,
          element: <Outlet />,
          children: [
            {
              path: config.PATH_URI.PROPERTY_SHEET.MAIN,
              element: <PropertySheetPage />,
            },
            {
              path: config.PATH_URI.PROPERTY_SHEET.DETAIL,
              element: <SheetDetailPage />,
            },
          ],
        },
      ],
    },
    {
      path: config.PATH_URI.LOGIN,
      element: isLogged ? <Navigate to={config.PATH_URI.HOME} /> : <Login />,
    },
    {
      path: config.PATH_URI.PROPERTY_LANDING_PAGE,
      element: <PropertyLandingPage />,
    },
    {
      path: config.PATH_URI.PROPERTY_ROOM_DETAILS,
      element: <PropertyRoomDetailsPage />,
    }
  ]);
  return router;
};

export default App;
