import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseQuery = async (args, api, extraOptions) => {
    const result = await fetchBaseQuery({
        baseUrl: 'https://wa5b4clkj5wlqp6k7izdv6enty0ioomi.lambda-url.us-east-1.on.aws',
    })(args, api, extraOptions);
    return result;
};

export const propertyApi = createApi({
    reducerPath: 'api',
    baseQuery,
    endpoints: (builder) => ({
        fetchCatalogProducts: builder.query({
            query: ({ page, perPage, search }) => ({
                url: `/`,
                // push last_evaluated_key: lastEvaluatedKey only if it is not null
                params: { page, per_page: perPage, search, last_evaluated_key: JSON.parse(localStorage.getItem("lastEvaluatedKey"))?.val || undefined },
            }),
        }),
    }),
});

export const { useFetchCatalogProductsQuery } = propertyApi;
