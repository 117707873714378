import React from "react";
import { Box, Typography } from "@mui/material";
import { ReactComponent as Movein } from "../../assets/icons/movein-ready.svg";
import { ReactComponent as SeamlessDelivery } from "../../assets/icons/seamless-delivery.svg";

const AboutReverie = () => {
    return (
        <div className="bg-gray-100 py-16 px-6">
            {/* Title */}
            <Typography variant="h4" className="text-center font-semibold mb-6">
                About Reverie 3D
            </Typography>

            {/* Description */}
            <div className="w-full flex justify-center items-center my-5">
                <Box className="w-full lg:w-[70%]">
                    <Typography variant="body1" align="center" className="text-center text-gray-700 mx-auto mb-12">
                        Indulge in the epitome of coastal luxury with this architectural masterpiece. Perched atop the bluffs of Malibu, Celestial Haven Estate offers breathtaking panoramic ocean views and world-class amenities, seamlessly blending indoor and outdoor living.
                    </Typography>
                </Box>
            </div>

            {/* Two Rows of Boxes */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 lg:max-w-5xl mx-auto">
                {/* First Row */}
                <Box className="bg-white rounded-lg p-8 shadow-lg w-full col-span-2 md:col-span-1">
                    <div className="flex items-center justify-center my-3">
                        <Movein />
                    </div>
                    <Typography variant="h6" className="text-center font-semibold">
                        Move-in ready Design
                    </Typography>
                    <Typography variant="body2" className="text-center text-gray-600 mt-2">
                        Whole Home Aesthetics
                    </Typography>
                </Box>

                <Box className="bg-white rounded-lg p-8 shadow-lg w-full col-span-2 md:col-span-1">
                    <div className="flex items-center justify-center my-3">
                        <SeamlessDelivery />
                    </div>
                    <Typography variant="h6" className="text-center font-semibold">
                        Seamless Delivery
                    </Typography>
                    <Typography variant="body2" className="text-center text-gray-600 mt-2">
                        Seamless Delivery
                    </Typography>
                </Box>

                {/* Second Row */}
                <Box className="bg-black rounded-lg p-5 shadow-lg flex flex-col md:flex-row justify-between w-full col-span-2">
                    <Box className=" rounded-lg p-8 shadow-lg text-white md:w-[48%]">
                        <Typography variant="h6" className="text-center font-semibold">
                            Designer-Approved Quality
                        </Typography>
                        <Typography variant="body2" className="text-center mt-2">
                            Includes all designed rooms and furnishings
                        </Typography>
                    </Box>

                    <Box className="bg-white rounded-lg p-8 shadow-lg md:w-[48%]">
                        <Typography variant="h6" className="text-center font-semibold">
                            Curated Quality
                        </Typography>
                        <Typography variant="body2" className="text-center text-gray-600 mt-2">
                            We vet the best of quality products and brands to bring to your home
                        </Typography>
                    </Box>
                </Box>
            </div>
        </div>
    );
};

export default AboutReverie;
