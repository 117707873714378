import React, { useCallback, useEffect, useState } from "react";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import Button from "../../components/Button";
import { ReactComponent as Search } from "../../assets/icons/search.svg";
import { config } from "../../constants";
import { IconButton, TextField as Input } from "@mui/material";
import PageWrapper from "./components/PageWrapper";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getImageLink } from "../../services/amplify/property";


const PropertySheetPage = () => {
  const navigate = useNavigate();
  const [query, querySet] = useState("");
  const [resultData, resultDataSet] = useState([]);
  const [notFoundText, notFoundTextSet] = useState("");

  const { properties } = useSelector(state => state.property);
  const searchQuery = useCallback(() => {
    if (!query) {
      resultDataSet(properties);
      notFoundTextSet("");
      return;
    }
    let _query = query.toLowerCase();
    let result = properties && properties.length &&
    properties.filter(property =>
      property.property_name.toLowerCase().includes(_query) ||
      property.street_address.toLowerCase().includes(_query)
    );
    resultDataSet(result);
    if (!result.length) {
      notFoundTextSet("No Property found ...");
    } else {
      notFoundTextSet("");
    }
    }, [query, properties]);

  useEffect(() => {
    const handler = setTimeout(() => {
      searchQuery();
    }, 500); // Debounce time in milliseconds

    return () => {
      clearTimeout(handler);
    };
  }, [query, searchQuery]);

  return (
    <div>
      <>
        <Sidebar>
          <div className="flex flex-col gap-10 w-full">
            <div className="flex flex-col gap-2 w-full">
              {/** HEADER & SEARCHBAR */}
              <Header text="Search for a property" includeBackBtn backBtnPath={config.PATH_URI.PRODUCT_CATALOG} />
              <div className="flex w-full items-stretch">
                <Input
                  value={query}
                  color="secondary"
                  onChange={e => querySet(e.target.value)}
                  className="bg-background-white w-full"
                  placeholder="Search Property..."
                  InputProps={{
                    startAdornment: (
                      <IconButton disabled>
                        <Search />
                      </IconButton>
                    ),
                    endAdornment: (
                      <Button disabled={!query} small onClick={searchQuery} className="!px-5">
                        Search
                      </Button>
                    ),
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col gap-5">
              {/** PROPERTY_CARD */}
              {resultData?.length ? (
                resultData?.map((property, index) => {
                  return (
                    <div
                      onClick={() => navigate(config.PATH_URI.PROPERTY_SHEET.DETAIL, { state: property })}
                      className="flex flex-col rounded-lg overflow-hidden shadow-lg bg-background-white cursor-pointer h-[324px] md:h-[340px] xl:h-[350px]"
                      key={index}
                    >
                      <div className="h-[75%]">
                        <img alt={property.property_name} src={getImageLink(property.thumbnail_image)} className="size-full object-cover object-center" />
                      </div>
                      <div className="flex-1 p-4 flex justify-center flex-col gap-2">
                        <div className="text-xl font-medium">{property.property_name}</div>
                        <div className="">{property.street_address + ", " + property?.city}</div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="">{notFoundText}</div>
              )}
            </div>
          </div>
        </Sidebar>
        <PageWrapper className="!hidden lg:!flex box-center">
          <div className="text-primary/60 pb-12">Search for a property to begin</div>
        </PageWrapper>
      </>
    </div>
  );
};

export default PropertySheetPage;
