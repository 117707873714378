import React from "react";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import useValidation from "../../formik/useValidation";
import toast from "react-hot-toast";
import { getUser, handleConfirmSignIn } from "../../services/amplify/helpers";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { setUser } from "../../store/slice/authSlice";


const ResetPasswordForm = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = React.useState(false);

    const formik = useValidation({
        initialValues: {
            password: "",
        },
        enableReinitialize: true,
        handleSubmit,
    });

    async function handleSubmit(values) {
        setIsLoading(true);
        let user, response;
        try {
            // user = await getUser();
            response = await handleConfirmSignIn({
                confirmationCode: values.password,
                //username: user?.signInDetails?.loginId
            });

        } catch (error) {
            console.error(error);
            toast.error("An error occurred. Please try again later.");
        } finally {
            setIsLoading(false);
        }

        if (response?.isSignedIn) {
            user = await getUser();
            dispatch(setUser({
                email: user?.signInDetails?.loginId,
                password: values.password
            }));
            const token = JSON.stringify(values);
            Cookies.set("jwt_token", token);
            toast.success("Login Successful");
        } else {
            toast.error(response?.error || "An error occurred. Please try again later.");
        }
    }

    return (
        <form
            autoComplete="false"
            className="px-6 py-10 max-w-[450px] sm:w-[450px] bg-background-white border border-primary/10 flex flex-col items-center rounded-lg gap-8"
        >
            <div className="flex flex-col gap-2 items-center">
                <div className="text-3xl font-semibold">Update Password</div>
                <div className="text-primary/50">Enter your new password to access your account</div>
            </div>
            <div className="w-full flex flex-col gap-6">
                <Input
                    error={formik.touched.password && !!formik.errors.password}
                    errorText={formik.touched.password && !!formik.errors.password && formik.errors.password}
                    name="password"
                    type="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    placeholder="Enter Password"
                    label="Password"
                    containerClassName="w-full"
                    passwordField
                    autoComplete="false"
                />
            </div>

            <Button submit primary onClick={formik.handleSubmit} loading={isLoading}>
                Change
            </Button>
        </form>
    );
};

export default ResetPasswordForm;