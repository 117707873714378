import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getUrl } from '@aws-amplify/storage';
import Header from "../../components/Header";
import Input from "../../components/Input";
import Checkbox from "../../components/Checkbox";
import { ReactComponent as Upload } from "../../assets/icons/upload.svg";
import { ReactComponent as Uploaded } from "../../assets/icons/success-tick.svg";
import { ReactComponent as Delete } from "../../assets/icons/delete.svg";
import Button from "../../components/Button";
import { IconButton, MenuItem } from "@mui/material";
import useValidation from "../../formik/useValidation";
import { propertyDetailSchema } from "../../formik/validationSchema";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import { config } from "../../constants";
import { updateProperty } from "../../services/amplify/property";
import { useSelector } from "react-redux";
import VirtualizedSelect from "../VirtualizedList/VirtualizedList";


const PropertyUpdatePage = () => {
    const [signedUrls, setSignedUrls] = useState({});
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [images, setImages] = useState({ projectFiles: [], beforeImages: [], thumbnail: null });
    const { editProperty: property } = useSelector(state => state.property);
    const [customRooms, setCustomRooms] = useState([]);

    const formik = useValidation({
        initialValues: {
            name: "",
            address: "",
            city: "",
            state: "",
            zipCode: "",
            type: "",
            squareFootage: "",
            clientName: "",
            clientEmail: "",
            includedRooms: [],
            notes: "",
            customRoom: ""
        },
        handleSubmit,
        enableReinitialize: true,
        stopReset: true,
        validationSchema: propertyDetailSchema,
    });

    useEffect(() => {
        const userCustomRooms = property?.included_rooms.filter(room => !config.INCLUDED_ROOMS.includes(room)) || [];
        setCustomRooms(userCustomRooms);

        formik.setValues({
            name: property?.property_name,
            address: property?.street_address,
            city: property?.city,
            state: property?.state,
            zipCode: property?.zip,
            type: property?.property_type,
            squareFootage: property?.square_footage,
            clientName: property?.client_name,
            clientEmail: property?.client_email,
            includedRooms: property?.included_rooms.filter(room => config.INCLUDED_ROOMS.includes(room)).concat(userCustomRooms),
            notes: property?.additional_notes,
        });

        setImages({
            projectFiles: property?.project_files,
            beforeImages: property?.before_images,
            thumbnail: property?.thumbnail_image,
        });

        // Generate signed URLs for existing images
        const generateSignedUrls = async () => {
            const urls = {};

            const createSignedUrl = async (key) => {
                const Obj = await getUrl({
                    path: key,
                    options: { expires: 3600 }
                });
                return Obj.url.href;
            };

            if (property?.project_files) {
                for (const file of property.project_files) {
                    const key = file.name || file;
                    urls[key] = await createSignedUrl(key);
                }
            }

            if (property?.before_images) {
                for (const file of property.before_images) {
                    const key = file.name || file;
                    urls[key] = await createSignedUrl(key);
                }
            }

            if (property?.thumbnail_image) {
                const key = property.thumbnail_image.name || property.thumbnail_image;
                urls[key] = await createSignedUrl(key);
            }

            setSignedUrls(urls);
        };

        generateSignedUrls();
    }, [property]);


    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;

        if (checked) {
            formik.setFieldValue("includedRooms", [...formik.values.includedRooms, name]);
        } else {
            formik.setFieldValue("includedRooms", formik.values.includedRooms.filter((room) => room !== name));
        }
    };

    const handleAddCustomRoom = () => {
        if (formik.values.customRoom && !customRooms.includes(formik.values.customRoom)) {
            setCustomRooms([...customRooms, formik.values.customRoom]);
            formik.setFieldValue("includedRooms", [...formik.values.includedRooms, formik.values.customRoom]);
            formik.setFieldValue("customRoom", "");
        }
    };

    const handleRemoveCustomRoom = (room) => {
        setCustomRooms(customRooms.filter(customRoom => customRoom !== room));
        formik.setFieldValue("includedRooms", formik.values.includedRooms.filter(includedRoom => includedRoom !== room));
    };

    function handleImageChange(event, key) {
        const selectedFiles = Array.from(event.target.files);
        if (selectedFiles.length > 10) {
            toast.error("Max 10 files can be uploaded at one time");
            return;
        }

        if (key === "thumbnail") {
            setImages(prev => ({ ...prev, thumbnail: selectedFiles[0] }));
            return;
        } else
            setImages(prev => ({
                ...prev,
                [key]: [...prev[key], ...selectedFiles],
            }));
    }

    function handleDelete(index, key) {
        setImages(prevFiles => ({ ...prevFiles, [key]: prevFiles[key].filter((_, i) => i !== index) }));
    }

    async function handleSubmit() {
        setLoading(true);
        try {
            const includedRooms = [...new Set([...formik.values.includedRooms, ...customRooms])];
            const response = await updateProperty(property?.id, { ...formik.values, includedRooms, images, userId: property?.userId });
            if (response) {
                toast.success("Property details updated successfully");
                navigate(config.PATH_URI.HOME);
            }
        } catch (error) {
            toast.error("Failed to update property details");
        } finally {
            setLoading(false);
        }
    }


    return (
        <div className="flex flex-col gap-6 !w-full">
            <Header text="Update Property Details" headerBtnText="Save Changes" isLoading={loading} headerBtnHandler={formik.handleSubmit} includeBackBtn backBtnPath={config.PATH_URI.HOME} />
            <div className="flex flex-col xl:flex-row gap-8 w-full">
                {/** PROPERTY_DETAIL_FORM */}
                <div className="flex flex-col bg-background-white shadow-md rounded-lg px-4 py-8 gap-6 flex-1 !overflow-x-hidden">
                    <Input
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        name="name"
                        error={formik.touched.name && !!formik.errors.name}
                        errorText={formik.touched.name && !!formik.errors.name && formik.errors.name}
                        placeholder="Enter Name"
                        label="Property Name"
                        lowerCaseLabel
                    />
                    <Input
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        name="address"
                        error={formik.touched.address && !!formik.errors.address}
                        errorText={formik.touched.address && !!formik.errors.address && formik.errors.address}
                        placeholder="1234 Reverie Lane"
                        label="Property Street Address"
                        lowerCaseLabel
                    />
                    <div className="grid grid-cols-1 lg:grid-cols-3 w-full gap-5">
                        <VirtualizedSelect name={"city"} label={"City"} lowerCaseLabel={true} formik={formik} />
                        <VirtualizedSelect name={"state"} label={"State"} lowerCaseLabel={true} formik={formik} />
                        <Input
                            value={formik.values.zipCode}
                            onChange={formik.handleChange}
                            name="zipCode"
                            error={formik.touched.zipCode && !!formik.errors.zipCode}
                            errorText={formik.touched.zipCode && !!formik.errors.zipCode && formik.errors.zipCode}
                            placeholder="Zip Code"
                            label="Zip Code"
                            lowerCaseLabel
                        />
                    </div>
                    <div className="flex w-full flex-col md:flex-row gap-6">
                        <Input
                            value={formik.values.type}
                            onChange={formik.handleChange}
                            name="type"
                            error={formik.touched.type && !!formik.errors.type}
                            errorText={formik.touched.type && !!formik.errors.type && formik.errors.type}
                            placeholder="New Build Multi-Unit Property"
                            label="Property Type"
                            lowerCaseLabel
                            containerClassName="md:w-1/2"
                            select
                        >
                            {config.ROOM_TYPES.map((type, index) => (
                                <MenuItem
                                    value={type}
                                    onClick={() => {
                                        formik.values.type === type ? formik.setFieldValue("type", "") : formik.setFieldValue("type", type);
                                    }}
                                    key={index}
                                >
                                    {type}
                                </MenuItem>
                            ))}
                        </Input>

                        <Input
                            value={formik.values.squareFootage}
                            onChange={formik.handleChange}
                            name="squareFootage"
                            error={formik.touched.squareFootage && !!formik.errors.squareFootage}
                            errorText={formik.touched.squareFootage && !!formik.errors.squareFootage && formik.errors.squareFootage}
                            placeholder="7000"
                            label="Property Square Footage"
                            lowerCaseLabel
                            containerClassName="md:w-1/2"
                        />
                    </div>
                    <div className="flex w-full flex-col md:flex-row gap-6">
                        <Input
                            value={formik.values.clientName}
                            onChange={formik.handleChange}
                            name="clientName"
                            error={formik.touched.clientName && !!formik.errors.clientName}
                            errorText={formik.touched.clientName && !!formik.errors.clientName && formik.errors.clientName}
                            placeholder="Elizabeth Spaulding"
                            label="Client Name"
                            lowerCaseLabel
                            containerClassName="md:w-1/2"
                        />
                        <Input
                            value={formik.values.clientEmail}
                            onChange={formik.handleChange}
                            name="clientEmail"
                            error={formik.touched.clientEmail && !!formik.errors.clientEmail}
                            errorText={formik.touched.clientEmail && !!formik.errors.clientEmail && formik.errors.clientEmail}
                            placeholder="Elizabeth@reverie3d.com"
                            label="Client Email Address"
                            lowerCaseLabel
                            containerClassName="md:w-1/2"
                        />
                    </div>
                    <div className="flex flex-col gap-3">
                        <div className="text-sm font-medium">Included Rooms</div>
                        <div className="grid grid-cols-2 gap-4">
                            {config.INCLUDED_ROOMS.map((room) => (
                                <Checkbox
                                    key={room}
                                    name={room}
                                    label={room}
                                    checked={formik.values.includedRooms.includes(room)}
                                    onChange={handleCheckboxChange}
                                    className="pl-1 !font-normal !leading-3 !w-fit"
                                />
                            ))}
                        </div>
                        {customRooms.map((room, index) => (
                            <div key={index} className="flex items-center gap-4 mt-2">
                                <Checkbox
                                    name={room}
                                    label={room}
                                    checked={formik.values.includedRooms.includes(room)}
                                    onChange={handleCheckboxChange}
                                    className="pl-1 !font-normal !leading-3 !w-fit"
                                />
                                <IconButton onClick={() => handleRemoveCustomRoom(room)}>
                                    <Delete />
                                </IconButton>
                            </div>
                        ))}
                        <div className="flex items-center gap-4 mt-4">
                            <Input
                                value={formik.values.customRoom}
                                onChange={formik.handleChange}
                                name="customRoom"
                                placeholder="Enter Custom Room"
                                lowerCaseLabel
                                containerClassName="md:w-1/2"
                            />
                            <Button small className="h-full" onClick={handleAddCustomRoom}>
                                Add
                            </Button>
                        </div>
                    </div>
                    <Input
                        value={formik.values.notes}
                        onChange={formik.handleChange}
                        name="notes"
                        error={formik.touched.notes && !!formik.errors.notes}
                        errorText={formik.touched.notes && !!formik.errors.notes && formik.errors.notes}
                        label="Additional Notes"
                        lowerCaseLabel
                        placeholder="Enter additional information such as which rooms we are designing for and special preferences. .."
                        multiline
                        rows={2}
                        sx={{
                            "& .MuiInputBase-inputMultiline": {
                                minHeight: "50px",
                                maxWidth: "100%",
                            },
                        }}
                    />
                </div>

                {/** PROPERTY_DETAIL_SIDE */}
                <div className="flex flex-col gap-8 w-full xl:max-w-[500px] 2xl:max-w-[700px]">
                    {/** PROPERTY_IMAGES */}
                    <div className="flex flex-col bg-background-white shadow-md rounded-lg px-4 py-8 gap-6 w-full">
                        <div className="text-sm font-medium">Project Files</div>

                        <div className="flex items-center justify-between">
                            <div className="flex items-center gap-4">
                                <div className="bg-background-gray size-12 rounded-full box-center">
                                    <Upload />
                                </div>
                                <div className="flex flex-col gap-0.5">
                                    <div className="text-sm font-medium">Upload Your Document</div>
                                    <div className="text-sm text-primary/50">Any format • Max. 500MB</div>
                                </div>
                            </div>
                            <Button className={`relative`} small>
                                <input
                                    type="file"
                                    onChange={e => handleImageChange(e, "projectFiles")}
                                    className="w-11 opacity-0 absolute inset-0 z-20 cursor-pointer"
                                    multiple
                                />
                                <span className="cursor-pointer">Upload</span>
                            </Button>
                        </div>

                        {images.projectFiles.map((image, index) => (
                            <div key={index} className="flex items-center justify-between">
                                <div className="flex items-center gap-4">
                                    <div className="bg-success/10 size-12 rounded-full box-center">
                                        <Uploaded />
                                    </div>
                                    <div className="flex flex-col gap-0.5">
                                        <a href={signedUrls[image.name || image]} className="text-sm font-medium" target="_blank" rel="noopener noreferrer">
                                            {image.name || image?.split("/")?.pop()}
                                        </a>
                                        <div className="text-sm text-primary/50">
                                            {dayjs().format("DD MMM, YYYY h:mma")} • {image?.size && Math.round(image.size / 1024)}KB
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center gap-0.5">
                                    <IconButton onClick={() => handleDelete(index, "projectFiles")}>
                                        <Delete />
                                    </IconButton>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/** BEFORE_IMAGES */}
                    <div className="flex flex-col bg-background-white shadow-md rounded-lg px-4 py-8 gap-6 w-full">
                        <div className="text-sm font-medium">Before Images</div>

                        <div className="flex items-center justify-between">
                            <div className="flex items-center gap-4">
                                <div className="bg-background-gray size-12 rounded-full box-center">
                                    <Upload />
                                </div>
                                <div className="flex flex-col gap-0.5">
                                    <div className="text-sm font-medium">Upload Your Document</div>
                                    <div className="text-sm text-primary/50">Any format • Max. 500MB</div>
                                </div>
                            </div>
                            <Button className={`relative`} small>
                                <input
                                    type="file"
                                    onChange={e => handleImageChange(e, "beforeImages")}
                                    className="w-11 opacity-0 absolute inset-0 z-20 cursor-pointer"
                                    multiple
                                    accept="image/*"
                                />
                                <span className="cursor-pointer">Upload</span>
                            </Button>
                        </div>

                        {images.beforeImages.map((image, index) => (
                            <div key={index} className="flex items-center justify-between">
                                <div className="flex items-center gap-4">
                                    <div className="bg-success/10 size-12 rounded-full box-center">
                                        <Uploaded />
                                    </div>
                                    <div className="flex flex-col gap-0.5">
                                        <a href={signedUrls[image.name || image]} className="text-sm font-medium" target="_blank" rel="noopener noreferrer">
                                            {image.name || image?.split("/")?.pop()}
                                        </a>
                                        <div className="text-sm text-primary/50">
                                            {dayjs().format("DD MMM, YYYY h:mma")} • {image?.size && Math.round(image.size / 1024)}KB
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center gap-0.5">
                                    <IconButton onClick={() => handleDelete(index, "beforeImages")}>
                                        <Delete />
                                    </IconButton>
                                </div>
                            </div>
                        ))}

                    </div>
                    {/** Thumbnail Image */}
                    <div className="flex flex-col bg-background-white shadow-md rounded-lg px-4 py-8 gap-6 w-full">
                        <div className="text-sm font-medium">Thumbnail Image</div>

                        <div className="flex items-center justify-between">
                            <div className="flex items-center gap-4">
                                <div className="bg-background-gray size-12 rounded-full box-center">
                                    <Upload />
                                </div>
                                <div className="flex flex-col gap-0.5">
                                    <div className="text-sm font-medium">Upload Your Thumbnail Image</div>
                                    <div className="text-sm text-primary/50">Image • Max. 2MB</div>
                                </div>
                            </div>
                            <Button className={`relative`} small>
                                <input
                                    type="file"
                                    onChange={e => handleImageChange(e, "thumbnail")}
                                    className="w-11 opacity-0 absolute inset-0 z-20 cursor-pointer"
                                    accept="image/*"
                                />
                                <span className="cursor-pointer">Upload</span>
                            </Button>
                        </div>

                        {images.thumbnail && (
                            <div className="flex items-center justify-between">
                                <div className="flex items-center gap-4">
                                    <div className="bg-success/10 size-12 rounded-full box-center">
                                        <Uploaded />
                                    </div>

                                    <div className="flex flex-col gap-0.5">
                                        <a href={signedUrls[images.thumbnail.name || images.thumbnail]} className="text-sm font-medium" target="_blank" rel="noopener noreferrer">
                                            {images.thumbnail?.name || images?.thumbnail?.split("/")?.pop()}
                                        </a>
                                        <div className="text-sm text-primary/50">
                                            {dayjs().format("DD MMM, YYYY h:mma")} • {images.thumbnail.size && Math.round(images.thumbnail.size / 1024)}KB
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center gap-0.5">
                                    <IconButton onClick={() => setImages(prev => ({ ...prev, thumbnail: null }))}>
                                        <Delete />
                                    </IconButton>
                                </div>
                            </div>
                        )}
                    </div>

                    {/** PROPERTY_SHEET */}
                    <div className="flex flex-col bg-background-white shadow-md rounded-lg px-4 py-8 gap-4 w-full">
                        <div className="text-sm font-medium">Property Sheet</div>
                        <Button small onClick={() => navigate(config.PATH_URI.PROPERTY_SHEET.DETAIL, { state: property })}>Create Property Sheet</Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PropertyUpdatePage;
