import { Box, Typography } from '@mui/material'
import React from 'react'

const Footer = () => {
    return (
        <Box className='bg-black'>
            <Typography variant='body1' className='text-center p-5 text-white'>
                ©
                {" " + new Date().getFullYear() + " "}
                Reverie3D. All Right reserved.
            </Typography>
        </Box>
    )
}

export default Footer