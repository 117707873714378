import { Skeleton } from '@mui/material'
import React from 'react'

const PropertySkeleton = ({
    containerClassName
}) => {
    return (
        <div className={`w-full h-full flex flex-col gap-6 min-h-[70px] ${containerClassName}`}>
            <Skeleton variant='text' width='100%' height='50px' />
            <div className='flex flex-col-reverse md:flex-row items-center justify-between gap-4'>
                <div className='flex w-full items-stretch max-w-[800px]'>
                    <Skeleton variant='rectangular' width='100%' height='50px' />
                </div>
                <div className='shrink-0'>
                    <Skeleton variant='rectangular' width='100%' height='50px' />
                </div>
            </div>
        </div>
    )
}

export const PageSkeleton = () => {
    return (
        <Skeleton variant='rectangular' width='100%' height='100vh' />
    )
}
export default PropertySkeleton