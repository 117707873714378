import * as Yup from "yup";

const createValidationSchema = fieldName => {
  return Yup.string().required(`${fieldName} is required`);
};

const emailValidation = Yup.string().email("Invalid Email").required("Email is required");

const loginSchema = Yup.object().shape({
  email: emailValidation,
  password: createValidationSchema("Password"),
  rememberMe: Yup.boolean(),
});

const resetPasswordSchema = Yup.object().shape({
  isCodeSent: Yup.boolean().default(false),
  email: emailValidation,
  // conditionally required if isCodeSent is true
  newPassword: Yup.string().when("isCodeSent", {
    is: true,
    then: () => createValidationSchema("New Password"),
  }),
  confirmationCode: Yup.string().when("isCodeSent", {
    is: true,
    then: () => createValidationSchema("Confirmation Code"),
  }),
});

const updatePasswordSchema = Yup.object().shape({
  password: createValidationSchema("Password"),
});


const propertyDetailSchema = Yup.object().shape({
  name: createValidationSchema("Property Name"),
  address: createValidationSchema("Address"),
  city: createValidationSchema("City"),
  state: createValidationSchema("State"),
  zipCode: createValidationSchema("Zip Code"),
  type: createValidationSchema("Property Type"),
  squareFootage: createValidationSchema("Property Square Footage"),
  clientName: createValidationSchema("Client Name"),
  clientEmail: emailValidation,
});

export { loginSchema, propertyDetailSchema, resetPasswordSchema, updatePasswordSchema };
