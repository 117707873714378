import { Dialog, DialogContent } from "@mui/material";

const Modal = ({ open, openSet, children, maxWidth = "lg" }) => {
  function handleClose() {
    open && openSet(false);
  }
  return (
    <Dialog open={open} maxWidth={maxWidth} fullWidth onClose={handleClose}>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default Modal;
