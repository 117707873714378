import React from "react";
import { useMediaQuery } from "@mui/material";
import { config } from "../../../constants";
import BackButton from "../../../components/BackButton/BackButton";

const Header = ({ text = "", includeBackBtn = false, backBtnPath = -1 }) => {
  const isSmallScreen = useMediaQuery(config.MD_BREAKPOINT);
  return (
    <div className="flex items-center justify-between">
      <div className={`flex items-center ${isSmallScreen ? "justify-between" : "gap-1"} w-full`}>
        {includeBackBtn && <BackButton to={backBtnPath} />}
        <div className="text-2xl leading-6 font-medium">{text}</div>
        {isSmallScreen && <div className="w-10" />}
      </div>
    </div>
  );
};

export default Header;
