import { confirmSignUp, signIn, signOut, signUp, resetPassword, confirmSignIn, getCurrentUser, confirmResetPassword } from 'aws-amplify/auth';
import { config } from '../../constants';

async function handleSignUp({ username, password, email }) {
    try {
        const { isSignUpComplete, userId, nextStep } = await signUp({
            username,
            password,
            options: {
                userAttributes: {
                    email,
                },
                // optional
                autoSignIn: true // or SignInOptions e.g { authFlowType: "USER_SRP_AUTH" }
            }
        });

        return {
            isSignUpComplete,
            userId,
            nextStep
        };

    } catch (error) {
        console.log('error signing up:', error);
        return {
            isSignUpComplete: false,
            error: error?.toString()?.split(':').pop() || config.ERROR
        };
    }
}

async function handleSignIn({ username, password }) {
    try {
        const { isSignedIn, nextStep } = await signIn({ username, password });
        console.log('isSignedIn:', isSignedIn, 'nextStep:', nextStep);
        return {
            isSignedIn,
            error: !isSignedIn ? nextStep?.signInStep === 'CONFIRM_SIGN_UP' ? "Please confirm your account" : "Error occured while signing in" : null,
            nextStep
        }
    } catch (error) {
        console.log('error signing in:', error);
        return {
            isSignedIn: false,
            error: error?.toString()?.split(':').pop() || config.ERROR,
            nextStep: null
        }
    }
}


async function handleSignUpConfirmation({ username, confirmationCode }) {
    try {
        const { isSignUpComplete, nextStep } = await confirmSignUp({
            username,
            confirmationCode
        });

        return {
            isSignUpComplete,
            nextStep
        };
    } catch (error) {
        console.log("error confirming sign up:", error);
        return {
            isSignUpComplete: false,
            error: error?.toString()?.split(':').pop() || config.ERROR
        }
    }
}

async function handleSignOut() {
    try {
        await signOut();
        return {
            isSignedOut: true
        };
    } catch (error) {
        console.log('error signing out: ', error);
        return {
            isSignedOut: false,
            error: error?.toString()?.split(':').pop() || config.ERROR
        };
    }
}

async function handleConfirmSignIn({ username, confirmationCode }) {
    try {
        const { isSignedIn, nextStep } = await confirmSignIn({
            email: username,
            confirmationCode,
            userAttributes: {
                email: username
            },
            challengeResponse: confirmationCode
        });

        console.log('isSignedIn:', isSignedIn, 'nextStep:', nextStep);
        return {
            isSignedIn,
            nextStep
        };
    } catch (error) {
        console.log('error confirming sign in:', error);
        return {
            isSignedIn: false,
            error: error?.toString()?.split(':').pop() || config.ERROR
        }
    }
}

async function getUser() {
    const { signInDetails, username, userId } = await getCurrentUser();
    return {
        signInDetails,
        username,
        userId
    };
}

async function handleResetPassword({ username }) {
    try {
        const { isPasswordReset, nextStep } = await resetPassword({
            username
        });

        console.log('isPasswordReset:', isPasswordReset, 'nextStep:', nextStep);
        return {
            isPasswordReset,
            nextStep
        };
    } catch (error) {
        console.log('error resetting password:', error);
        return {
            isPasswordReset: false,
            error: error?.toString()?.split(':').pop() || config.ERROR
        }
    }
}

async function handleConfirmResetPassword({ username, newPassword, confirmationCode }) {
    try {
        await confirmResetPassword({
            username,
            newPassword,
            confirmationCode
        });

        return {
            isPasswordReset: true
        };
    } catch (error) {
        console.log('error confirming reset password:', error);

        return {
            isPasswordReset: false,
            error: error?.toString()?.split(':').pop() || config.ERROR
        }
    }
}


export { handleSignUp, handleSignIn, handleSignUpConfirmation, handleSignOut, handleConfirmSignIn, getUser, handleResetPassword, handleConfirmResetPassword };