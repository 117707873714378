import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar/Navbar'
import HeroSection from '../../components/HeroSection/PropertyHeroSection'
import PropertyInfo from '../../components/HeroSection/PropertyInfo'
import AboutReverie from '../../components/HeroSection/AboutReverie'
import ExploreRooms from '../../components/HeroSection/ExploreRooms'
import Footer from '../../components/HeroSection/Footer'
import { useFetchPropertySheetVersionsQuery } from '../../store/api/propertySheetApi'
import { useParams } from 'react-router-dom'
import { PageSkeleton } from '../../components/PropertySkeleton/PropertySkeleton'

const PropertyLandingPage = () => {
    // query pid, mkp, uId from URL
    const params = new URLSearchParams(window.location.search)
    let propertyId = params.get('pid')
    let userId = params.get('uId')
    let { id: propertySheetId } = useParams();

    const { isLoading, data: selectedSheet, error } = useFetchPropertySheetVersionsQuery({ propertyId, userId, propertySheetId })

    return (
        <>
            <Navbar login={true} logoAlign='start' landingPage={true} />
            {
                isLoading ?
                    <PageSkeleton />
                    :
                    error ?
                        <p className='text-center text-red-500'>Error fetching the property. Please try again</p>
                        :
                        <>
                            <HeroSection id={propertyId} slides={
                                selectedSheet?.rooms?.filter(slide => slide.images && slide.images.length > 0)
                            } propertyData = {selectedSheet?.propertyDetails} />
                            <div className='bg-[#F2F2F2] p-10'>
                                <PropertyInfo data={selectedSheet} />
                                <AboutReverie />
                                <ExploreRooms data={selectedSheet} userId={userId} propertyId={propertyId} propertySheetId={propertySheetId} />
                            </div>
                        </>
            }
            <Footer />
        </>
    )
}

export default PropertyLandingPage