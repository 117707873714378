import React, { useEffect, useState } from "react";
import { ReactComponent as Logo } from "../../assets/logo/black_logo_.svg";
import { ReactComponent as MainLogo } from "../../assets/logo/Logo.svg";
import { ReactComponent as SmallLogo } from "../../assets/logo/snall-logo.svg";
import { ReactComponent as Hamburger } from "../../assets/icons/hamburger.svg";
import { IconButton } from "@mui/material";

const Navbar = ({ login = false, openSideBar, logoAlign = "center", landingPage = false }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Set `isScrolled` to true when the page is scrolled at least one full viewport height
      setIsScrolled(window.scrollY >= window.innerHeight);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`${isScrolled ? "fixed" : "absolute"} top-0 left-0 right-0 bg-background-white border-b border-b-primary/20 flex items-center z-50 ${login ? "p-5 justify-center" : "p-3 justify-between"
        } shadow-sm ${logoAlign === "center" ? "!justify-center" : "!justify-start"}`}
    >
      {!login && (
        <IconButton onClick={openSideBar}>
          <Hamburger />
        </IconButton>
      )}
      {landingPage ? <div className="flex items-center">
        <SmallLogo className="w-[56px]" />
        <Logo className="w-[126px]" />
      </div> :
        <MainLogo />}
      {!login && <div className="flex w-10"></div>}
    </div>
  );
};

export default Navbar;
