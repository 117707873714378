import React, { useCallback, useEffect, useState } from "react";
import Header from "../../components/Header";
import Input from "../../components/Input";
import { ReactComponent as Search } from "../../assets/icons/search.svg";
import Button from "../../components/Button";
import { useMediaQuery } from "@mui/material";
import { config } from "../../constants";
import { useNavigate } from "react-router-dom";
import { getProperties } from "../../services/amplify/property";
import PropertyCard from "../../components/PropertyCard";
import PropertySkeleton from "../../components/PropertySkeleton";
import { useDispatch, useSelector } from "react-redux";
import { setProperties } from "../../store/slice/propertySlice";
import PageWrapper from "../../components/PageWrapper";

// TODO: Implement the skeleton loader for the properties
// TODO: Implement redux for the properties
const HomePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery(config.MD_BREAKPOINT);
  const [query, querySet] = useState("");
  const [resultData, resultDataSet] = useState([]);
  const [loading, loadingSet] = useState(false);

  const properties = useSelector((state) => state.property.properties);

  useEffect(() => {
    loadingSet(true);
    async function fetchData() {
      const response = await getProperties();
      loadingSet(false);
      dispatch(setProperties(response));
      resultDataSet(response);
    }
    fetchData();
  }, [dispatch]);

  const searchQuery = useCallback(() => {
    const lowerCaseQuery = query.toLowerCase();
    resultDataSet(
      properties.filter(
        (property) =>
          property.property_name.toLowerCase().includes(lowerCaseQuery) ||
          property?.street_address?.toLowerCase().includes(lowerCaseQuery)
      )
    );
  }, [properties, query]);

  useEffect(() => {
    const handler = setTimeout(() => {
      searchQuery();
    }, 500); // Debounce time in milliseconds

    return () => {
      clearTimeout(handler);
    };
  }, [query, searchQuery]);

  return (
    <PageWrapper>
      <div className="w-full h-full flex flex-col gap-6">
        {/** HEADER */}
        <Header text="Reverie 3D" />
        {/** SEARCH & ADD_NEW BUTTON */}
        <div className="flex flex-col-reverse md:flex-row items-center justify-between gap-4">
          <div className="flex w-full items-stretch max-w-[800px]">
            <Input
              value={query}
              onChange={(e) => querySet(e.target.value)}
              className="bg-background-white rounded-l-lg !rounded-r-none"
              containerClassName="w-full"
              placeholder="Search Properties..."
              leftIcon={<Search />}
            />
            <Button
              onClick={() => searchQuery()}
              className="!rounded-l-none"
              small
            >
              Search
            </Button>

          </div>
          <div
            className={`${isSmallScreen ? "fixed w-full bottom-2 px-4 z-50" : "shrink-0"
              }`}
          >
            <Button
              onClick={() => navigate(config.PATH_URI.PROPERTY_CREATE)}
            >
              Add New Property
            </Button>
          </div>
        </div>

        {/** PROPERTIES */}
        <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-4">
          {/** PROPERTIES_CARD */}
          {loading ? (
            Array.from({ length: 4 }).map((_, index) => {
              return <PropertySkeleton key={index} skeleton containerClassName={'!min-h-[400px]'} />;
            })
          ) : resultData.length ? (
            resultData.map((property, index) => {
              return <PropertyCard key={index} property={property} />;
            })
          ) : (
            <div className="">No Property found ...</div>
          )}
        </div>
      </div>
    </PageWrapper>
  );
};

export default HomePage;
