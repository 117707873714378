import React from "react";
import { Pagination as MuiPagination, PaginationItem } from "@mui/material";
import { ReactComponent as PrevIcon } from "../../assets/icons/prev.svg";
import { ReactComponent as NextIcon } from "../../assets/icons/next.svg";

const Pagination = ({ className = "", page, pageSet, count }) => {
  function handleChange(e, value) {
    pageSet(value);
  }
  return (
    <MuiPagination
      onChange={handleChange}
      page={page}
      count={count}
      className={className}
      size="large"
      renderItem={items => {
        return <PaginationItem {...items} slots={{ previous: PrevIcon, next: NextIcon }} />;
      }}
    />
  );
};

export default Pagination;
