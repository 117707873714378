
import { City, State } from "country-state-city";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import React, { useState, useMemo, useRef, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import { FixedSizeList as List } from 'react-window';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from "react-redux";
import { setState } from "../../store/slice/propertySlice";

const VirtualizedSelect = ({
    label,
    name,
    lowerCaseLabel = false,
    formik
}) => {
    const dispatch = useDispatch()
    const selectedState = useSelector((state) => state.property?.extra?.state);
    const states = useMemo(() => State.getStatesOfCountry('US'), []);
    const cities = useMemo(() => {
        return selectedState ? City.getCitiesOfState('US', selectedState?.isoCode) : City.getCitiesOfCountry('US')
    }, [selectedState]);

    useEffect(() => {
        setSearchQuery(formik.values[name]);
        // TODO: fix dependency array
    }, [formik.values, name]);

    const [searchQuery, setSearchQuery] = useState('');
    const [open, setOpen] = useState(false);
    const inputRef = useRef(null);

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
        if (name === "state") {
            dispatch(setState(null));
        }
        setOpen(true);
    };

    const handleSelect = (res) => {
        formik.setFieldValue(name, res?.name);
        if (name === "state") {
            formik.setFieldValue("city", "")
            dispatch(setState(res));
        } else if (name === "city") {
            let state = State.getStateByCodeAndCountry(res?.stateCode, "US");
            dispatch(setState(state));
            formik.setFieldValue("state", state?.name);
        }
        setSearchQuery(res?.name);
        setOpen(false);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    const filteredResults = useMemo(() => {
        let filteredResults = [];
        if (searchQuery) {
            if (name === 'city') {
                filteredResults = cities.filter((city) => city?.name?.toLowerCase().includes(searchQuery.toLowerCase()));
            } else if (name === 'state') {
                filteredResults = states.filter((state) => state?.name?.toLowerCase().includes(searchQuery.toLowerCase()));
            }
        }
        return filteredResults;
    }, [searchQuery, cities, name, states]);

    const rowRenderer = ({ index, style }) => {
        const result = filteredResults[index];
        return (
            <MenuItem
                key={result?.name}
                value={result?.name}
                style={style}
                onClick={() => handleSelect(result)}
            >
                {result?.name}
            </MenuItem>
        );
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div>
                <label htmlFor={name} className={`${!lowerCaseLabel && "uppercase"} text-sm font-medium`}>
                    {label}
                </label>
                <TextField
                    //disabled={name === "city" && formik.values.state === "" ? true : false}
                    fullWidth
                    value={searchQuery}
                    onChange={handleSearch}
                    variant="outlined"
                    placeholder="Search..."
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    onClick={() => setOpen(true)}
                    ref={inputRef}
                    error={formik.touched[name] && formik.errors[name] ? true : false}
                />
                {!open && formik.touched[name] && formik.errors[name] && (
                    <div className="text-xs text-danger">{formik.errors[name]}</div>
                )}
                {open && filteredResults?.length > 0 && (
                    <Paper
                        style={{
                            position: 'absolute',
                            zIndex: 1,
                            marginTop: '10px',
                            width: inputRef.current ? inputRef.current.offsetWidth : '100%',
                        }}
                    >
                        <List
                            height={400}
                            itemCount={filteredResults?.length}
                            itemSize={50}
                            width="100%"
                        >
                            {rowRenderer}
                        </List>
                    </Paper>
                )}
            </div>
        </ClickAwayListener>
    );
};

export default VirtualizedSelect;
