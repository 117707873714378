import React, { useEffect, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { ReactComponent as Delete } from "../../assets/icons/delete.svg";
import ImageComponent from "../Image/ImageComponent";
import { useDispatch } from "react-redux";
import { rearrangeImages } from "../../store/slice/propertySlice";

const RoomImages = ({ selectedRoom, propertySheet, canvasImageSet, handleDeleteRoomImage, isFormUpdatedSet }) => {
  console.log('RoomImages:', selectedRoom, propertySheet);
  const dispatch = useDispatch();

  const [roomImages, setRoomImages] = useState(
    selectedRoom
      ?.images || []
  );

  // Track dragging state
  const [draggedIndex, setDraggedIndex] = useState(null);

  useEffect(() => {
    setRoomImages(selectedRoom?.images || []);
  }, [selectedRoom]);

  // Handle drag start
  const handleDragStart = (index) => {
    setDraggedIndex(index);
  };

  // Handle drag over
  const handleDragOver = (e) => {
    e.preventDefault(); // Allow drop
  };

  // Handle drop
  const handleDrop = (index) => {
    const updatedImages = [...roomImages];
    const [removed] = updatedImages.splice(draggedIndex, 1); // Remove dragged image
    updatedImages.splice(index, 0, removed); // Insert dragged image at drop location

    setRoomImages(updatedImages); // Update images
    let payload = {
      name: selectedRoom.name,
      images: updatedImages,
    }

    dispatch(rearrangeImages(payload));
    setDraggedIndex(null); // Reset dragged index
    isFormUpdatedSet(true);
  };

  console.log('RoomImages:', roomImages);

  return (
    <Box display="flex" gap={2}>
      {roomImages.map((image, index) => (
        <Box
          key={image.id}
          position="relative"
          onClick={() => canvasImageSet(image.src)}
          draggable
          onDragStart={() => handleDragStart(index)}
          onDragOver={handleDragOver}
          onDrop={() => handleDrop(index)}
        >
          {/* Image Component */}
          <ImageComponent imageKey={image.src} className="size-16 rounded-lg !object-cover" />

          {/* Delete Icon */}
          <IconButton
            className="!bg-white !rounded-full !p-1 !absolute !top-[-5px] !right-[-5px]"
            onClick={(e) => {
              e.stopPropagation(); // Prevent click from opening the image on canvas
              handleDeleteRoomImage(image.id);
            }}
          >
            <Delete className="!text-xs !size-4" color="error" />
          </IconButton>
        </Box>
      ))}
    </Box>
  );
};

export default RoomImages;
