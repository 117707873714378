import React, { useEffect, useState } from 'react';
import { Box, Popover, Typography } from '@mui/material';
import Button from '../Button';
import Input from '../Input';
import { getUser } from '../../services/amplify/helpers';
import toast from 'react-hot-toast';


const ShareDesign = ({
  propertyId,
  propertySheetId,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [markupValue, setMarkupValue] = useState(3); // Default value
  const [user, userSet] = useState(null);
  const [generatedLink, setGeneratedLink] = useState('');

  useEffect(() => {
    getUser().then(user => userSet(user))
    console.log(user);

  }, [])

  useEffect(() => {
    if (user) {
      setGeneratedLink(process.env.REACT_APP_BASE_URL + '/property-landing-page/' + propertySheetId + `?pid=${propertyId}&mkp=${markupValue}&uId=${user.userId}`);
    }
  }, [user, propertyId, propertySheetId, markupValue])

  // Handle popover open/close
  const handlePopover = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleShareClick = () => {
    // check if it supports clipboard otherwise use a fallback method
    if (!navigator.clipboard) {
      toast.error('Clipboard not supported. Please copy manually');
      return;
    }
    navigator.clipboard.writeText(generatedLink);
    toast.success('Link copied to clipboard');
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);

  return (
    <Box display="flex" alignItems="center" gap={2}>
      {/* Other components like Upload Button and Images here */}

      {/* Share Button */}
      <Box ml="auto">
        <Button
          variant="contained"
          color="primary"
          onClick={handlePopover}
          aria-describedby={open ? 'share-popover' : undefined}
          className="h-12 !w-24"
        >
          Share
        </Button>

        {/* Popover that shows the share content */}
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          disableRestoreFocus
        >
          <Box p={3} sx={{ maxWidth: 400, borderRadius: '10px' }}>
            {/* Markup Value */}
            <Typography variant="subtitle1" gutterBottom>
              Markup Value
            </Typography>
            <Box display="flex" alignItems="center" mb={3}>
              <Input
                type="number"
                value={markupValue}
                onChange={(e) => setMarkupValue(e.target.value)}
                inputProps={{ min: 0, max: 100, style: { textAlign: 'center' } }}
                sx={{ width: '60px' }}
                className='h-10 !border-gray-200 rounded-lg'
              />
              <Typography variant="body2" sx={{ ml: 1 }}>
                %
              </Typography>
            </Box>

            {/* Shareable Link */}
            <Typography variant="subtitle1" gutterBottom>
              Share your shoppable designs publicly
            </Typography>
            <Box display="flex" alignItems="center" gap={1}>
              <Input
                type="text"
                value={generatedLink}
                className='h-10 !border-gray-200 rounded-lg'
              />
              <Button
                variant="contained"
                color="primary"
                sx={{ minWidth: '70px' }}
                onClick={() => handleShareClick()}
                className='h-10 !rounded-lg'
              >
                Copy
              </Button>
            </Box>
          </Box>
        </Popover>
      </Box>
    </Box>
  );
};

export default ShareDesign;
