import React from "react";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import useValidation from "../../formik/useValidation";
import toast from "react-hot-toast";
import { ReactComponent as EmailIcon } from "../../assets/icons/email.svg";
import { handleConfirmResetPassword, handleResetPassword } from "../../services/amplify/helpers";
import { resetPasswordSchema } from "../../formik/validationSchema";
import { config } from "../../constants";


const ForgotPasswordForm = ({
    setToggleForm
}) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [isCodeSent, setIsCodeSent] = React.useState(false);

    const formik = useValidation({
        initialValues: {
            email: "",
            newPassword: "",
            confirmationCode: "",
            isCodeSent: false
        },
        handleSubmit,
        validationSchema: resetPasswordSchema,
        stopReset: true
    });


    async function handleSubmit(values) {
        setIsLoading(true);
        if (formik.values.isCodeSent) {
            let response = await handleConfirmResetPassword({
                username: values.email,
                newPassword: values.newPassword,
                confirmationCode: values.confirmationCode
            });
            if (response.isPasswordReset) {
                toast.success("Password reset successful");
                setToggleForm(config.AUTH_STEPS.SIGN_IN)
            }
            else if (response.error) {
                toast.error(response.error);
            }

            setIsLoading(false);
            return;
        }


        let response = await handleResetPassword({
            username: values.email
        })
        if (response.nextStep?.resetPasswordStep === config.AUTH_STEPS.CONFIRM_RESET_PASSWORD_WITH_CODE) {
            toast.success("Password reset code sent to " + response.nextStep?.codeDeliveryDetails?.deliveryMedium);
            formik.setFieldValue("isCodeSent", true);
        } else if (response.error) {
            throw toast.error(response.error);
        }

        setIsLoading(false);
    }


    return (
        <form
            className="px-6 py-10 max-w-[450px] sm:w-[450px] bg-background-white border border-primary/10 flex flex-col items-center rounded-lg gap-8"
        >
            <div className="flex flex-col gap-2 items-center">
                <div className="text-3xl font-semibold">Forgot Password</div>
                <div className="text-primary/50">Enter your email address to reset your password</div>
            </div>
            <div className="w-full flex flex-col gap-6">
                <Input
                    error={formik.touched.email && !!formik.errors.email}
                    errorText={formik.touched.email && !!formik.errors.email && formik.errors.email}
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    placeholder="Enter Email"
                    label="Email Address"
                    containerClassName="w-full"
                    rightIcon={<EmailIcon />}
                />
                {
                    formik.values.isCodeSent &&
                    <>
                        <Input
                            error={formik.touched.newPassword && !!formik.errors.newPassword}
                            errorText={formik.touched.newPassword && !!formik.errors.newPassword && formik.errors.newPassword}
                            name="newPassword"
                            type="password"
                            value={formik.values.newPassword}
                            onChange={formik.handleChange}
                            placeholder="Enter New Password"
                            label="New Password"
                            containerClassName="w-full"
                            passwordField
                        />
                        <Input
                            error={formik.touched.confirmationCode && !!formik.errors.confirmationCode}
                            errorText={formik.touched.confirmationCode && !!formik.errors.confirmationCode && formik.errors.confirmationCode}
                            name="confirmationCode"
                            value={formik.values.confirmationCode}
                            onChange={formik.handleChange}
                            placeholder="Enter Confirmation Code"
                            label="Confirmation Code"
                            containerClassName="w-full"
                        />
                    </>

                }
            </div>

            <Button submit primary onClick={formik.handleSubmit} loading={isLoading}>
                Send
            </Button>
        </form>
    );
};

export default ForgotPasswordForm;