import React, { useCallback, useState } from "react";
import { Icon, IconButton, TextField as MuiInput } from "@mui/material";
import { ReactComponent as PasswordHidden } from "../../assets/icons/password-hidden.svg";
import { ReactComponent as PasswordVisible } from "../../assets/icons/password-visible.svg";

const Input = ({
  type = "text",
  name = "",
  containerClassName = "",
  label = "",
  placeholder = "",
  className = "",
  rightIcon = null,
  leftIcon = null,
  passwordField = false,
  value,
  onChange = () => null,
  error = false,
  errorText = "",
  lowerCaseLabel = false,
  ...props
}) => {
  const [localType, localTypeSet] = useState(type);

  const changePasswordType = useCallback(() => {
    localType === "password" ? localTypeSet("text") : localTypeSet("password");
  }, [localType]);
  return (
    <div className={`${containerClassName} flex flex-col ${(label || error) && "gap-0.5"}`}>
      {label && (
        <label htmlFor={name} className={`${!lowerCaseLabel && "uppercase"} text-sm font-medium`}>
          {label}
        </label>
      )}
      <MuiInput
        {...props}
        color="secondary"
        value={value}
        name={name}
        type={localType}
        error={error}
        onChange={onChange}
        // className={`${className}`}
        placeholder={placeholder}
        InputProps={{
          endAdornment: passwordField ? (
            <IconButton onClick={changePasswordType}> {localType === "text" ? <PasswordHidden /> : <PasswordVisible />}</IconButton>
          ) : (
            rightIcon && <IconButton disabled>{rightIcon}</IconButton>
          ),
          startAdornment: leftIcon && <IconButton disabled>{leftIcon}</IconButton>,
          className: className,
        }}
      />
      {error && <div className="text-xs text-danger">{errorText}</div>}
    </div>
  );
};

export default Input;
