import React from 'react'
import getCurrency from '../../utils/getCurrency';

const CatalogCard = ({
    product,
    isModalOpenSet,
    modalDataSet
}) => {
    let data = typeof product?.JsonFeedData === "string" && JSON.parse(product?.JsonFeedData)
    let productType = data?.productType?.split(">")?.pop()?.trim()

    return (
        <div
            onClick={() => {
                isModalOpenSet(true);
                modalDataSet(data);
            }}
            className="bg-background-white h-[230px] shadow-lg rounded-lg cursor-pointer flex gap-4 p-6"
        >
            {/** IMAGE */}
            <div className="aspect-square w-1/3 rounded-md overflow-hidden">
                <img src={data.image_link} alt={data.title} className="size-full object-cover object-center" />
            </div>

            {/** NAME & DESCRIPTION */}
            <div className="flex flex-col gap-2 flex-1 justify-between">
                <div className="flex flex-col gap-0.5">
                    <div className="text-lg font-semibold">{data.title?.substring(0, 30)} {data.title?.length > 30 ? "..." : ""}</div>
                    <div className="text-primary/50 uppercase">{productType?.substring(0, 30)} {productType?.length > 30 ? "..." : ""}</div>
                </div>
                <div className="line-clamp-2 text-primary/80">{data.description}</div>

                {/** PRICE */}
                <div className="text-lg font-semibold">{getCurrency(data.price)}</div>
            </div>
        </div>
    );
}

export default CatalogCard