import React, { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { ReactComponent as Left } from "../../assets/icons/arrow-left.svg";
import { ReactComponent as Right } from "../../assets/icons/arrow-right.svg";
import { createSignedUrl } from "../../services/amplify/property";

const HeroSection = ({ slides, propertyData }) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [currentBatchIndex, setCurrentBatchIndex] = useState(0);
    const [signedUrls, setSignedUrls] = useState([]);
    const batchSize = 3;
    const slideInterval = 5000;
    
    useEffect(() => {
        const fetchSignedUrls = async () => {
            if (!slides || slides.length === 0) return;
            const urls = await Promise.all(
                slides.map(slide => createSignedUrl(slide.images[0].src)) // Only the first image in each slide
            );

            setSignedUrls(urls);
            setSelectedImage(urls[0]);
        };

        fetchSignedUrls();
    }, [slides]);


    useEffect(() => {
        if (!signedUrls || signedUrls.length === 0) return;

        let currentIndex = 0;

        const interval = setInterval(() => {
            currentIndex = (currentIndex + 1) % signedUrls.length;
            setSelectedImage(signedUrls[currentIndex]);
        }, slideInterval);

        return () => clearInterval(interval);
    }, [signedUrls]);

    const handleNextBatch = () => {
        if (currentBatchIndex + batchSize < slides.length) {
            setCurrentBatchIndex(currentBatchIndex + batchSize);
        }
    };

    const handlePreviousBatch = () => {
        if (currentBatchIndex - batchSize >= 0) {
            setCurrentBatchIndex(currentBatchIndex - batchSize);
        }
    };

    const currentBatch = slides
        ?.slice(currentBatchIndex, currentBatchIndex + batchSize)
        ?.filter(slide => slide.images && slide.images.length > 0); // Only slides with images

    return (
        <div className="relative h-screen bg-gray-100 mt-16">
            {/* Background Image */}
            <div
                className="absolute top-0 left-0 w-full h-full bg-cover bg-center"
                style={{ backgroundImage: `url("${selectedImage}")` }}
            />

            {/* Overlay for dark effect */}
            <div className="absolute top-0 left-0 w-full h-full bg-black opacity-20" />

            {/* Content */}
            <div className="relative z-10 text-center text-white flex flex-col justify-center items-center h-full">
                    <>
                        <h1 className="text-5xl font">
                            {propertyData?.property_name}
                        </h1>
                        <p className="text-lg mt-2">
                            {`${propertyData?.street_address}, ${propertyData?.city}, ${propertyData?.state}, ${propertyData?.zip}`}
                        </p>
                    </>

                {/* Slideshow Thumbnails */}
                <div className="mt-8 flex justify-evenly items-center space-x-4 absolute bottom-10 w-full">
                    {/* Thumbnails for the current batch */}
                    <div className="flex justify-center items-center space-x-4">
                        {
                            signedUrls?.length > 0 &&
                            currentBatch?.map((_, index) => (
                                <div
                                    key={index}
                                    onClick={() => setSelectedImage(signedUrls[currentBatchIndex + index])} // Set selected image on click
                                    className="cursor-pointer"
                                >
                                    {/* Display thumbnail with signed URL */}
                                    <img
                                        src={signedUrls[currentBatchIndex + index]}
                                        alt={`Slide ${index + 1}`}
                                        className="w-36 h-24 rounded-md object-cover"
                                    />
                                </div>
                            ))}
                    </div>

                    {/* Navigation Buttons for Batches */}
                    <div className="flex items-center space-x-4">
                        <IconButton onClick={handlePreviousBatch} disabled={currentBatchIndex === 0}>
                            <div className="p-2 rounded-full border-[1px] border-white">
                                <Left />
                            </div>
                        </IconButton>
                        <IconButton onClick={handleNextBatch} disabled={currentBatchIndex + batchSize >= slides.length}>
                            <div className="bg-white p-2 rounded-full">
                                <Right />
                            </div>
                        </IconButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeroSection;
