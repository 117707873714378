import React from 'react'
import UpdatePropertyForm from '../../components/UpdatePropertyForm'
import PageWrapper from '../../components/PageWrapper'

const PropertyUpdatePage = () => {
    return (
        <PageWrapper>
            <UpdatePropertyForm />
        </PageWrapper>
    )
}

export default PropertyUpdatePage