import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Input from "../../components/Input";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { config } from "../../constants";
import { ReactComponent as Search } from "../../assets/icons/search.svg";
import Pagination from "../../components/Pagination/Pagination";
import PageWrapper from "../../components/PageWrapper";
import CatalogDetailModal from '../../components/CatalogDetailModal';
import CatalogCard from "../../components/CatalogCard";
import { useFetchCatalogProductsQuery } from "../../store/api/propertyApi";
import PropertySkeleton from "../../components/PropertySkeleton";


const ProductCatalogPage = () => {
  const navigate = useNavigate();
  const [isModalOpen, isModalOpenSet] = useState(false);
  const [modalData, modalDataSet] = useState(null);
  const isSmallScreen = useMediaQuery(config.MD_BREAKPOINT);
  const [query, querySet] = useState("");
  const [search, searchSet] = useState("");
  const [resultData, resultDataSet] = useState([]);
  const [page, pageSet] = useState(1);
  // const [lastEvaluatedKey, lastEvaluatedKeySet] = useState(null);
  const itemsPerPage = 9;

  // do not refetch data if lastEvaluatedKey is changed
  const { isLoading, data, isFetching } = useFetchCatalogProductsQuery({ page, perPage: itemsPerPage, search: search }, {
    refetchOnMountOrArgChange: false,
    refetchOnReconnect: false,
    refetchOnFocus: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (data) {
      resultDataSet(data?.data)
      // do not refetch data if lastEvaluatedKey is changed only refetch when page is changed

      // if (lastEvaluatedKey !== data?.last_evaluated_key && lastEvaluatedKey !== null && !isFetching && data?.last_evaluated_key && data?.page !== page) {
      // lastEvaluatedKeySet({val: data?.last_evaluated_key})
      localStorage.setItem('lastEvaluatedKey', JSON.stringify({ val: data?.last_evaluated_key }))
      // }
    }
  }, [data])

  let searchTimeout = null
  const searchQuery = (query) => {
    querySet(query)
    // cancel the previous search
    clearTimeout(searchTimeout)
    // delay search
    searchTimeout = setTimeout(() => {
      searchSet(query)
    }, 3000)
  }
  return (
    <>
      <CatalogDetailModal open={isModalOpen} openSet={isModalOpenSet} data={modalData} />
      <PageWrapper>
        <div className="w-full h-full flex flex-col gap-12">
          <div className="flex flex-col gap-6 h-full">
            {/* HEADER */}
            <Header text="Product Catalog" />
            {/* SEARCH & ADD_NEW BUTTON */}
            <div className="flex flex-col-reverse md:flex-row items-center justify-between gap-4">
              <div className="flex w-full items-stretch max-w-[800px]">
                <Input
                  value={query}
                  onChange={e => {
                    //querySet(e.target.value)
                    searchQuery(e.target.value)
                  }}
                  className="bg-background-white !rounded-r-none"
                  containerClassName="w-full"
                  placeholder="Search Products..."
                  leftIcon={<Search />}
                />
              </div>
              <div className={`${isSmallScreen ? "fixed w-full bottom-2 px-4 z-50" : "shrink-0"}`}>
                <Button onClick={() => navigate(config.PATH_URI.PROPERTY_SHEET.MAIN)}>Create Property Sheet</Button>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
            {/** CATALOG_CARD */}
            {(isLoading || isFetching) ?
              Array.from({ length: 9 }).map((_, index) => {
                return <PropertySkeleton key={index} skeleton />;
              })
              :
              resultData?.length ? (
                resultData?.map((catalog, index) => {
                  return (
                    <CatalogCard
                      key={index}
                      product={catalog}
                      catalog={catalog}
                      isModalOpenSet={isModalOpenSet}
                      modalDataSet={modalDataSet}
                    />
                  );
                })
              ) : (
                <div>No products found...</div>
              )}
          </div>

          {!(isLoading || isFetching) && <Pagination className="mx-auto" page={page} pageSet={pageSet} count={Math.ceil(data?.total / itemsPerPage)} />}
        </div>
      </PageWrapper>
    </>
  );
};




export default ProductCatalogPage;