import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../../constants';

const baseQuery = async (args, api, extraOptions) => {
    const result = await fetchBaseQuery({
        baseUrl: config.LAMBDA_URL.PROPERTY_SHEET,
    })(args, api, extraOptions);
    return result;
};

const getEventData = () => { }
export const propertySheetApi = createApi({
    reducerPath: 'propertySheetApi',
    tagTypes: ['PropertySheets'],
    baseQuery,
    endpoints: (builder) => ({
        fetchPropertySheets: builder.query({
            query: (data) => ({
                url: `/propertySheets?userId=${data?.userId}`,
                method: 'GET'
                //params: { page, per_page: perPage, search },
            }),
            providesTags: ['PropertySheets']
        }),
        fetchPropertySheetVersions: builder.query({
            query: ({ propertySheetId, propertyId, userId, roomId }) => {
                const params = new URLSearchParams();

                // Append query parameters based on provided values
                if (propertySheetId) params.append('propertySheetId', propertySheetId);
                if (propertyId) params.append('propertyId', propertyId);
                if (userId) params.append('userId', userId);
                if (roomId) params.append('roomId', roomId);

                return {
                    url: `/propertySheets?${params.toString()}`,
                    method: 'GET'
                };
            },
            // transformResponse: (response) => {
            //     if (response && response.rooms && Array.isArray(response.rooms)) {
            //         return {
            //             ...response,
            //             rooms: response.rooms.map(room => ({
            //                 ...room,
            //                 items: room.items?.map(item => ({
            //                     id: item.id,
            //                     title: item.title,
            //                     description: item.description,
            //                     brand: item.brand,
            //                     productType: item.productType,
            //                     image_link: item.image_link
            //                 }))
            //             }))
            //         };
            //     }
            //     return response;
            // },
            providesTags: ['PropertySheets']
        }),

        createPropertySheet: builder.mutation({
            query: (data) => ({
                url: `/propertySheets`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['PropertySheets']
        }),
        deletePropertySheet: builder.mutation({
            query: (property) => ({
                url: `/${property.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['PropertySheets']
        }),
        updatePropertySheet: builder.mutation({
            query: (data) => ({
                url: `/propertySheets`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['PropertySheets']
        }),
    }),
});

export const {
    useFetchPropertySheetsQuery,
    useFetchPropertySheetVersionsQuery,
    useCreatePropertySheetMutation,
    useDeletePropertySheetMutation,
    useUpdatePropertySheetMutation,
} = propertySheetApi;