import React from "react";
import { CircularProgress, Button as MuiButton } from "@mui/material";

const Button = ({
  small = false,
  submit = false,
  primary = false,
  children = null,
  loading = false,
  disabled = false,
  className = "",
  onClick = () => null,
  ...props
}) => {
  return (
    <MuiButton
      {...props}
      type={submit ? "submit" : "button"}
      disabled={loading || disabled}
      onClick={onClick}
      className={`${className} ${small ? "!w-fit !px-3 !py-2" : "!px-5"}`}
    >
      {loading ? <CircularProgress size={25} /> : children}
    </MuiButton>
  );
};

export default Button;
