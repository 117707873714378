import React from "react";
import Checkout from "../Checkout/Checkout";

const PropertyInfo = ({
  data
}) => {
  return (
    <div className="flex flex-col md:flex-row gap-3 md:gap-0 justify-between items-center bg-white rounded-lg shadow-md px-6 py-4">
      {/* Property Title with Avatar */}
      <div className="flex items-center space-x-3">
        <span className="text-lg">{
          data?.name ||
          "Celestial Haven Estate"
        }</span>
      </div>

      {/* Dotted Separator */}
      <div className="flex-1 mx-6 border-dotted border-b-2 border-gray-300"></div>

      {/* Price and Button */}
      <div className="flex items-center space-x-4">
        <span className="text-2xl font-bold">${
          data?.buyerTotal
        }</span>

        <Checkout />
      </div>
    </div>
  );
};

export default PropertyInfo;
