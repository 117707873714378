import { get, post, del, put } from 'aws-amplify/api';
import { uploadData, getProperties as fileProperties, getUrl } from 'aws-amplify/storage';
import { S3Client, GetObjectCommand } from '@aws-sdk/client-s3';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import { getUser } from './helpers';
import Papa from 'papaparse';

// Utility function to generate file names
const generateFileName = (file) => {
    const timestamp = Date.now();
    const sanitizedFileName = file.name.replace(/\s+/g, '-');
    return `${timestamp}-${sanitizedFileName}`;
};

// Utility function to upload files
const uploadFiles = async (files, folder) => {
    const uploadPromises = files.map(file => {
        const fileName = generateFileName(file);
        const path = `${folder}/${fileName}`;
        return uploadFile(file, path);
    });

    const results = await Promise.all(uploadPromises);
    return results.filter(result => result !== null);
};

async function postProperty(data) {
    // upload images first
    // images: {projectFiles: [File], beforeImages: [File], thumbnail: File}
    const { images } = data;
    const imageKeys = Object.keys(images);

    let imageUploadPromises = [];
    for (let i = 0; i < imageKeys.length; i++) {
        const key = imageKeys[i];
        const files = images[key];

        if (key === 'thumbnail') {
            if (files === null || files?.length === 0) {
                continue;
            }
            imageUploadPromises.push(uploadFile(files, `images/properties/${key}`));
            continue;
        }
        for (let j = 0; j < files.length; j++) {
            imageUploadPromises.push(uploadFile(files[j], `images/properties/${key}`));
        }
    }

    let promises = await Promise.allSettled(imageUploadPromises);


    // post property

    delete data.images;

    try {
        let dataToSend = {
            userId: data.userId,
            property_name: data.name,
            street_address: data.address,
            city: data.city,
            state: data.state,
            zip: data.zipCode,
            property_type: data.type,
            square_footage: data.squareFootage,
            client_name: data.clientName,
            client_email: data.clientEmail,
            included_rooms: data.includedRooms,
            additional_notes: data.notes,
            thumbnail_image: promises.find(promise => promise.status === 'fulfilled' && promise.value.path.includes('thumbnail'))?.value?.path || null,
            project_files: promises.filter(promise => promise.status === 'fulfilled' && promise.value.path.includes('projectFiles')).map(promise => promise.value.path),
            before_images: promises.filter(promise => promise.status === 'fulfilled' && promise.value.path.includes('beforeImages')).map(promise => promise.value.path),
        }

        const restOperation = post({
            apiName: 'myProperty',
            path: '/properties',
            options: {
                body: dataToSend
            }
        });

        const { body } = await restOperation.response;
        const response = await body.json();

        return response;
    } catch (e) {
        console.log('POST call failed: ', e);
    }
}

async function getProperties() {
    let user = await getUser();
    try {
        const restOperation = get({
            apiName: 'myProperty',
            path: '/properties?userId=' + user.userId,
        });

        const { body } = await restOperation.response;
        const response = await body.json();
        return response;
    }
    catch (e) {
        console.log('GET call failed: ', e);
        return [];
    }

}

async function getPropertyDetails(id) {
    let user = await getUser();
    try {
        const restOperation = get({
            apiName: 'myProperty',
            path: '/properties/' + id,
        });

        const { body } = await restOperation.response;
        const response = await body.json();
        return response;
    }
    catch (e) {
        console.log('GET call failed: ', e);
        return [];
    }

}

async function deleteProperty(property) {
    try {
        const restOperation = del({
            apiName: 'myProperty',
            path: `/properties/${property.id}`
        });

        const { body } = await restOperation.response;
        const response = await body.json();

        return response;
    } catch (e) {
        console.log(e);
        console.log('DELETE call failed: ');
    }
}

async function updateProperty(id, data) {
    const { images } = data;

    const imageKeys = Object.keys(images);

    let imageUploadPromises = [];
    for (let i = 0; i < imageKeys.length; i++) {
        const key = imageKeys[i];
        const files = images[key];
        if (key === 'thumbnail') {
            if (files === null || files?.length === 0) {
                continue;
            }

            imageUploadPromises.push(uploadFile(files, `images/properties/${key}`));
            continue;
        }
        for (let j = 0; j < files.length; j++) {
            imageUploadPromises.push(uploadFile(files[j], `images/properties/${key}`));
        }
    }

    let uploadResults = await Promise.allSettled(imageUploadPromises);

    uploadResults.forEach((promise, index) => {
        if (promise.status === 'fulfilled') {
            console.log(`Image ${index} upload succeeded`);
        } else {
            console.log(`Image ${index} upload failed`);
        }
    });

    if (uploadResults.some(promise => promise.status === 'rejected')) {
        console.log('One or more image uploads failed');
        return;
    }

    // Here both types of images are present these might be only urls or files
    // So we need to check if the image is a file or a url
    let beforeImages, thumbnail, projectFiles;
    try {
        thumbnail = images.thumbnail ? images.thumbnail instanceof File ? uploadResults.find(promise => promise.status === 'fulfilled' && promise?.value?.path?.includes("thumbnail"))?.value?.path : images.thumbnail : null;

        projectFiles = images.projectFiles.map(file => {
            if (file instanceof File) {
                return uploadResults.find(promise => promise.status === 'fulfilled' && promise?.value?.path?.includes("projectFiles"))?.value?.path;
            }
            return file;
        });

        beforeImages = images.beforeImages.map(file => {
            if (file instanceof File) {
                return uploadResults.find(promise => promise.status === 'fulfilled' && promise?.value?.path?.includes("beforeImages"))?.value?.path;
            }
            return file;
        });

    } catch (error) {
        console.log('Error in uploading images: ', error);
    }
    delete data.images;

    let dataToSend = {
        userId: data.userId,
        property_name: data.name,
        street_address: data.address,
        city: data.city,
        state: data.state,
        zip: data.zipCode,
        property_type: data.type,
        square_footage: data.squareFootage,
        client_name: data.clientName,
        client_email: data.clientEmail,
        included_rooms: data.includedRooms,
        additional_notes: data.notes,
        thumbnail_image: thumbnail,
        project_files: projectFiles,
        before_images: beforeImages,
    };

    try {
        const restOperation = put({
            apiName: 'myProperty',
            path: `/properties/${id}`,
            options: {
                body: dataToSend
            }
        });

        const { body } = await restOperation.response;
        const response = await body.json();

        console.log('PUT call succeeded');
        return response;
    } catch (e) {
        console.log('PUT call failed: ', e);
        throw e;
    }
}

async function uploadFile(file, folder = "images/properties") {
    if (!(file instanceof File)) {
        console.log(file + 'Not a file');
        return;
    }

    try {
        const response = await uploadData({
            path: `${folder}/${generateFileName(file)}`,
            data: file,
        }).result;

        return response;
    } catch (e) {
        console.log('Upload failed: ', e);
        throw e;
    }
}

async function loadFurniture() {
    // from s3 bucket on url: https://reverie-sftp.s3.amazonaws.com/wayfair/bquxjob_6cc5ec2_18f59a41dc6.csv
    // const response = await fetch('https://reverie-sftp.s3.amazonaws.com/wayfair/bquxjob_6cc5ec2_18f59a41dc6.csv');
    // const data = await response.text();
    // console.log(data);
    // return data;
    try {
        fileProperties({
            path: 'wayfair/bquxjob_6cc5ec2_18f59a41dc6.csv'
        })
    } catch (error) {
        console.log('Error loading furniture: ', error);
    }
}

const fetchCsv = async () => {
    console.log("Fetching CSV file");
    const s3Client = new S3Client({
        region: 'us-east-1',
        credentials: fromCognitoIdentityPool({
            clientConfig: { region: 'us-east-1' },
            identityPoolId: 'us-east-1:6575c152-6cd2-4ad8-96d9-be4b0c958738',
        }),
    });

    const params = {
        Bucket: 'reverie-sftp', // Specify the bucket name
        Key: 'wayfair/bquxjob_6cc5ec2_18f59a41dc6.csv', // Specify the file key
    };

    try {
        const command = new GetObjectCommand(params);
        const data = await s3Client.send(command);


        const csvContent = (await data.Body.transformToString()).toString();

        let myResult = null;
        Papa.parse(csvContent, {
            header: true,
            complete: (result) => {
                myResult = result?.data ?? [];
            },
            error: (error) => {
                console.error('Error while parsing CSV:', error);
                myResult = [];
            },
        });

        console.log('RESULT:', myResult[0]);
        return myResult;
    } catch (error) {
        console.error('Error fetching or parsing CSV file:', error);
        return [];
    }
};

const fetchCsvData = async () => {
    // from lambda function CatalogProductsProcessor
    // let restOperation = get({
    //     apiName: "ProductCatalogProcessor",
    //     path: '/'
    // });

    // https://wa5b4clkj5wlqp6k7izdv6enty0ioomi.lambda-url.us-east-1.on.aws
    fetch('https://wa5b4clkj5wlqp6k7izdv6enty0ioomi.lambda-url.us-east-1.on.aws', {
        headers: {
            //Authorization: `Bearer ${accessToken}`
        }
    })
        .then(response => response.json())
        .then(data => {
            console.log(data);
        })
        .catch(error => {
            console.log("ERROR: ");
            console.error(error);
        });

    // const { body } = await restOperation.response;
    // const response = await body.json();

    // console.log('GET call succeeded');
    // console.log(response);
}
const getImageLink = (address) => {
    if (address === null) return '/plain-black.jpg';
    return "https://reverie3df3913-dev.s3.amazonaws.com/" + address;
}

const getCatalogFormattedData = (catalog) => {
    if (typeof (catalog) !== "array") {
        console.log("Catalog is not an array");
        let data = typeof catalog?.JsonFeedData === "string" && JSON.parse(catalog?.JsonFeedData);
        let productType = data?.productType?.split(">")?.pop()?.trim();
        return {
            ...data,
            productType,
        }
    }
    return catalog.map(product => {
        const data = typeof product?.JsonFeedData === "string" && JSON.parse(product?.JsonFeedData);
        const productType = data?.productType?.split(">")?.pop()?.trim();
        return {
            ...data,
            productType,
        };
    });
}

export const createSignedUrl = async (key) => {
    const Obj = await getUrl({
        path: key,
        options: { expires: 3600 }
    });
    return Obj.url.href;
};
/**
 * 
 * @param {int} shardId : Shard ID number between 0 and 64
 * @returns 
 */
function generateRowId(shardId) {
    let CUSTOMEPOCH = 1300000000000;
    let ts = new Date().getTime() - CUSTOMEPOCH;
    let randid = Math.floor(Math.random() * 512);
    ts = (ts * 64);
    ts = ts + shardId;
    return (ts * 512) + randid;
}

export { postProperty, getProperties, uploadFile, deleteProperty, updateProperty, loadFurniture, fetchCsv, getImageLink, getCatalogFormattedData, generateRowId, fetchCsvData, getPropertyDetails };

/**
 * 
Papa.parse(csvContent, {
            header: true,
            complete: (result) => {
                
                cachedData = result?.data ?? [];
                return { data: "D" }
            },
            error: (error) => {
                console.error('Error while parsing CSV:', error);
                cachedData = [];
                return { data: [], message: "Empty" }
            },
        });
 */
