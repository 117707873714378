import React from "react";
import Button from "../Button";
import { useMediaQuery } from "@mui/material";
import { config } from "../../constants";
import BackButton from "../BackButton";

const Header = ({ text = "", headerBtnText = "", headerBtnHandler, includeBackBtn = false, backBtnPath = -1, isLoading = false }) => {
  const isSmallScreen = useMediaQuery(config.MD_BREAKPOINT);
  return (
    <div className="flex items-center justify-between">
      <div className={`flex items-center ${isSmallScreen ? "justify-between" : "gap-1"} w-full`}>
        {includeBackBtn && <BackButton to={backBtnPath} />}
        <div className="text-2xl md:text-3xl leading-6 font-medium">{text}</div>
        {isSmallScreen && <div className="w-10" />}
      </div>
      {headerBtnText && (
        <div className={`${isSmallScreen ? "fixed z-50 w-full bottom-2 pr-8" : "shrink-0"}`}>
          <Button onClick={headerBtnHandler} loading={isLoading}>{headerBtnText}</Button>
        </div>
      )}
    </div>
  );
};

export default Header;
