import React, { useCallback } from 'react'
import Navbar from '../../components/Navbar'
import { useLocation } from 'react-router-dom'
import { Container } from '@mui/material';
import ImageComponent from '../../components/Image/ImageComponent';
import PropertyInfo from '../../components/HeroSection/PropertyInfo';
import { ReactComponent as BackArrow } from "../../assets/icons/arrow-left-black.svg";
import Footer from '../../components/HeroSection/Footer';
import { useFetchPropertySheetVersionsQuery } from '../../store/api/propertySheetApi';

const PropertyRoomDetailsPage = () => {
    const { state } = useLocation();

    const { isLoading, data, error } = useFetchPropertySheetVersionsQuery({
        propertyId: state?.propertyId,
        userId: state?.userId,
        roomId: state?.room?.name,
        propertySheetId: state?.propertySheetId
    })

    return (
        <div className='w-[100vw]'>
            <Navbar login={true} logoAlign='start' landingPage={true} />
            <div className='mt-20' />
            <RoomDetail
                roomName={state?.room?.name}
                imageUrl={state?.room?.images[0]?.src}
                price={Number(state?.room?.cost).toFixed(2)}
                onQuickBuy={() => { }}
                onBack={() => { window.history.back() }}
            />
            {!isLoading &&
                data ?
                <ShopRoomDesign items={data?.rooms[0]?.items} />
                : error && <p className='text-center text-red-500'>Error fetching the property. Please try again</p>}
            <Footer />
        </div>
    )
}

const RoomDetail = ({ roomName, imageUrl, price, onQuickBuy, onBack }) => {
    return (
        <Container className="pt-10">
            {/* Back to Overview */}
            <div className="flex items-center cursor-pointer text-gray-500 mb-6" onClick={onBack}>
                <BackArrow className="mr-2" />
                <span className="text-md">Back to property overview</span>
            </div>

            {/* Room Image */}
            <div className="rounded-lg overflow-hidden mb-6">
                <ImageComponent imageKey={imageUrl} alt={roomName} className="w-full h-auto lg:min-h-[561px] lg:max-h-[561px] object-cover" />
            </div>

            {/* Room Details */}
            <PropertyInfo data={{ name: roomName, buyerTotal: price }} />
        </Container>
    );
};

const ShopRoomDesign = ({ items }) => {
    return (
        <div className="py-10 !bg-white !w-[100vw] mt-10">
            <Container className='lg:!max-w-[70%]'>
                {/* Section Title */}
                <h2 className="text-3xl font-light text-[#28282A] mb-8">Shop this room design</h2>

                {/* Items List */}
                <div className="space-y-8">
                    {items.map((item, index) => (
                        <RoomItem item={item} key={index} />
                    ))}
                </div>
            </Container>
        </div>
    );
};

const RoomItem = ({ item }) => {
    let data = useCallback(() => {
        if (typeof item?.JsonFeedData === "string") {
            return JSON.parse(item?.JsonFeedData)
        }
        return null;
    }, [item])();

    return (
        <div className="flex flex-col items-center md:flex-row md:items-start space-x-6 p-4 rounded-lg">
            {/* Item Image */}
            <div className="md:!min-w-44 md:!max-w-44 md:h-44 rounded-lg border-[1px] solid border-[#E1E6EA] p-5">
                <img src={data?.image_link} alt={item.id} className="w-full h-full object-cover rounded-lg" />
            </div>

            {/* Item Details */}
            <div className='mt-5 lg:mt-0'>
                <h3 className="text-2xl font-light text-[#28282A]">{
                    item.title?.substring(0, 30)} {item.title?.length > 30 ? "..." : ""
                    }</h3>
                <p className="text-sm text-[#BCC2C7] mb-2">{item.brand}</p>
                <p className="text-sm text-[#28282A]">{
                    item.description?.substring(0, 200)} {item.description?.length > 200 ? "..." : ""
                    }</p>
            </div>
        </div>
    );
}

export default PropertyRoomDetailsPage