import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import { config } from "../constants";

const baseQuery = fetchBaseQuery({
  baseUrl: config.SERVER,
  credentials: "include",
  prepareHeaders: headers => {
    headers.set("Access-Control-Allow-Origin", config.CLIENT);
    headers.set("Access-Control-Allow-Methods", "GET, POST, PUT, PATCH, DELETE, OPTIONS");
    const token = Cookies.get("jwt_token");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export default baseQuery;
