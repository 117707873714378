import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Input from "../../components/Input/Input";
import { ReactComponent as EmailIcon } from "../../assets/icons/email.svg";
import Button from "../../components/Button/Button";
import Checkbox from "../../components/Checkbox";
import useValidation from "../../formik/useValidation";
import { loginSchema } from "../../formik/validationSchema";
import toast from "react-hot-toast";
import { handleSignIn } from "../../services/amplify/helpers";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { setUser } from "../../store/slice/authSlice";
import ResetPasswordForm from "../../components/ResetPasswordForm";
import { config } from "../../constants";
import ForgotPasswordForm from "../../components/ForgotPasswordForm";


const LoginPage = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const [toggleForm, setToggleForm] = React.useState(config.AUTH_STEPS.SIGN_IN);

  const formik = useValidation({
    initialValues: {
      email: "",
      password: "",
      rememberMe: false,
    },
    validationSchema: loginSchema,
    enableReinitialize: true,
    handleSubmit,
    stopReset: true,
  });

  async function handleSubmit(values) {
    setIsLoading(true);
    try {
      let response = await handleSignIn({
        username: values.email,
        password: values.password
      });
      // if (response?.nextStep?.signInStep === "CONFIRM_SIGN_UP") {
      //   // TODO: handle confirm sign up
      //   // handleSignUpConfirmation({
      // }
      setIsLoading(false);

      if (response.isSignedIn) {
        dispatch(setUser(values));
        const token = JSON.stringify(values);
        if (values.rememberMe) {
          Cookies.set("jwt_token", token, { expires: 30 });
        } else {
          Cookies.set("jwt_token", token, { expires: 1 });
        }
        toast.success("Login Successful");
      } else {
        if (response.nextStep?.signInStep === config.AUTH_STEPS.CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED) {
          setToggleForm(config.AUTH_STEPS.CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED);
        } else {
          toast.error(response.error || "An error occurred. Please try again later.");
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred. Please try again later.");
    } finally {
      setIsLoading(false);
    }
    // login(values);
  }

  const handleForgotPassword = async () => {
    setToggleForm(config.AUTH_STEPS.RESET_PASSWORD)
  };

  return (
    <div className="h-screen w-screen box-center">
      {/** NAVBAR */}
      <Navbar login />

      <>
        {/** LOGIN_FORM */}
        {toggleForm === config.AUTH_STEPS.SIGN_IN ?
          <form
            autoComplete="false"
            className="px-6 py-10 max-w-[450px] sm:w-[450px] bg-background-white border border-primary/10 flex flex-col items-center rounded-lg gap-8"
          >
            <div className="flex flex-col gap-2 items-center">
              <div className="text-3xl font-semibold">Log In</div>
              <div className="text-primary/50">Enter your credentials to access your account</div>
            </div>
            <div className="w-full flex flex-col gap-6">
              <Input
                error={formik.touched.email && !!formik.errors.email}
                errorText={formik.touched.email && !!formik.errors.email && formik.errors.email}
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                placeholder="Enter Email"
                label="Email Address"
                containerClassName="w-full"
                rightIcon={<EmailIcon />}
              />
              <Input
                error={formik.touched.password && !!formik.errors.password}
                errorText={formik.touched.password && !!formik.errors.password && formik.errors.password}
                name="password"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                placeholder="Enter Password"
                label="Password"
                containerClassName="w-full"
                passwordField
                autoComplete="false"
              />
              <div className="flex justify-between w-full gap-2 items-center">
                <Checkbox name="rememberMe" checked={formik.values.rememberMe} onChange={formik.handleChange} label="Remember me for 30 days" />
                <div onClick={handleForgotPassword} className="text-secondary font-medium text-sm">
                  Forget Password?
                </div>
              </div>
            </div>

            <Button submit primary onClick={formik.handleSubmit} loading={isLoading}>
              Log into Account
            </Button>
          </form> :
          toggleForm === config.AUTH_STEPS.CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED ?
            <ResetPasswordForm /> :
            toggleForm === config.AUTH_STEPS.RESET_PASSWORD ?
              <ForgotPasswordForm setToggleForm={setToggleForm} /> : null
        }
      </>
    </div>
  );
};

export default LoginPage;
