export const config = {
  MD_BREAKPOINT: "(max-width: 768px)",
  PATH_URI: {
    HOME: "/",
    PROPERTY_CREATE: "/create",
    PROPERTY_DETAILS: "/detail",
    LOGIN: "/login",
    PROPERTY_UPDATE: "/property/update/:id",
    PRODUCT_CATALOG: "/product-catalog",
    PROPERTY_SHEET: {
      MAIN: "/property-sheet",
      get DETAIL() {
        return `${this.MAIN}/detail`;
      },
    },
    PROPERTY_LANDING_PAGE: "/property-landing-page/:id",
    PROPERTY_ROOM_DETAILS: "/property-landing-page/room/:id",
  },
  ERROR: "Internal Server Error",
  SERVER: "https://",
  ROOM_TYPES: [
    "New Build Single-Family Home",
    "Existing Single-Family Home",
    "New Build Multi-Unit Property",
    "Existing Multi-Unit Property",
  ],
  INCLUDED_ROOMS: [
    "Great room",
    "Living room",
    "Kitchen",
    "Dining room",
    "Family room",
    "Primary bedroom",
    "Primary bathroom",
    "Outdoor patio/terrace",
    "Additional bedrooms",
  ],
  AUTH_STEPS: {
    SIGN_UP: "SIGN_UP",
    CONFIRM_SIGN_UP: "CONFIRM_SIGN_UP",
    SIGN_IN: "SIGN_IN",
    CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED: "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED",
    RESET_PASSWORD: "RESET_PASSWORD",
    CONFIRM_RESET_PASSWORD_WITH_CODE: "CONFIRM_RESET_PASSWORD_WITH_CODE",
  },
  LAMBDA_URL: {
    CATALOG_PRODUCTS: 'https://wa5b4clkj5wlqp6k7izdv6enty0ioomi.lambda-url.us-east-1.on.aws',
    PROPERTY_SHEET: "https://xbyzl371xd.execute-api.us-east-1.amazonaws.com/dev"
  },
  STRIPE: {
    PK: process.env.REACT_APP_STRIPE_PK,
    PRICE_ID: process.env.REACT_APP_PRICE_ID
  },
};
