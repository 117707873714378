import React, { useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Button, Drawer, useMediaQuery, IconButton, Divider, Tooltip } from "@mui/material";
import { colors } from "../../themes/themes";
import { ReactComponent as Logo } from "../../assets/logo/snall-logo.svg";
import { ReactComponent as Home } from "../../assets/icons/home.svg";
import { ReactComponent as ProducCatalog } from "../../assets/icons/product-catalog.svg";
import { ReactComponent as Bag } from "../../assets/icons/bag.svg";
import { ReactComponent as Logout } from "../../assets/icons/logout.svg";
import { config } from "../../constants";
import { clearUser } from "../../store/slice/authSlice";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { handleSignOut } from "../../services/amplify/helpers";

const links = [
  { name: "Home", path: "/", icon: <Home /> },
  { name: "Product Catalog", path: "/product-catalog", icon: <Bag /> },
  { name: "Cart", path: "/property-sheet", icon: <ProducCatalog /> },
];

const Sidebar = ({ open, openSet }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  async function logout() {
    let response = await handleSignOut();
    if (response.isSignedOut) {
      dispatch(clearUser());
      toast.success("Logout Success");
    } else {
      toast.error(response.error)
    }
  }
  const isSmallScreen = useMediaQuery(config.MD_BREAKPOINT);
  function handleClose() {
    openSet(pre => !pre);
  }

  useEffect(() => {
    open && handleClose();
  }, [pathname]);

  return (
    <Drawer
      sx={{
        "& .MuiDrawer-paper": {
          width: isSmallScreen ? "96px" : "96px",
          borderRadius: "0px 20px 20px 0px",
        },
      }}
      anchor="left"
      open={open}
      onClose={handleClose}
      hideBackdrop={isSmallScreen ? false : true}
      variant={isSmallScreen ? "temporary" : "permanent"}
    >
      <div className="w-full h-full p-4 py-10 flex flex-col items-center justify-between">
        <div className="flex flex-col gap-10">
          <Logo />
          <Divider flexItem />
          <div className="flex flex-col gap-10">
            {links.map((link, index) => {
              return <Link key={index} link={link} isSmallScreen={isSmallScreen} />;
            })}
          </div>
        </div>

        <Tooltip title="Logout">
          <IconButton onClick={logout} className="mt-auto">
            <Logout />
          </IconButton>
        </Tooltip>
      </div>
    </Drawer>
  );
};

export default Sidebar;

const Link = ({ link, isSmallScreen }) => {
  return (
    <>
      <NavLink
        to={link.path || "#"}
        children={props => {
          return (
            <div className={`${props.isActive ? "" : ""} flex gap-2 items-center`}>
              {link.icon}
              {/* {isSmallScreen && <div className={`${props.isActive ? "" : ""}`}>{link.name}</div>} */}
            </div>
          );
        }}
      />
    </>
  );
};
