import React from "react";
import RoomCard from "./RoomCard";
import { useNavigate } from "react-router-dom";

const ExploreRooms = ({
    data,
    ...props
}) => {
    const params = new URLSearchParams(window.location.search)
    const navigate = useNavigate();

    let markupValue = params.get('mkp')

    const handleExploreRoom = (room) => {
        navigate(`/property-landing-page/room/${room.name?.replace(/ /g, "-")}`, { state: { room, ...props } });
    }

    return (
        <div className="bg-gray-100 py-16 md:px-6">
            {/* Title */}
            <h2 className="text-center text-2xl mb-10">
                Explore our designed rooms
            </h2>

            {/* Room Cards */}
            <div className="grid grid-cols-1 gap-6 max-w-5xl mx-auto">
                {
                    data?.rooms?.
                        filter(room => room.images && room.images.length > 0)?.
                        map((room, index) => (
                            <RoomCard
                                key={index}
                                imageSrc={room?.images[0]?.src || ""}
                                roomName={room?.name}
                                price={Number(Number(room?.cost) * (1 + markupValue / 100)).toFixed(2)}
                                handleExplore={() => handleExploreRoom(room)}
                            />
                        ))
                }
            </div>
        </div>
    );
};

export default ExploreRooms;
