import React, { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Sidebar from "../Sidebar";
import { useMediaQuery } from "@mui/material";
import Navbar from "../Navbar";
import { config } from "../../constants";

const Layout = () => {
  const location = useLocation();
  const isSmallScreen = useMediaQuery(config.MD_BREAKPOINT);
  const [open, openSet] = useState(false);
  function openSidebar() {
    openSet(pre => !pre);
  }
  if (location.pathname.includes(config.PATH_URI.PROPERTY_LANDING_PAGE.split("/")[1]))
    return <Outlet />;
  return (
    <>
      <Sidebar open={open} openSet={openSet} />
      {isSmallScreen && <Navbar openSideBar={openSidebar} />}
      <Outlet />
    </>
  );
};

export default Layout;
