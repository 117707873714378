import React, { useEffect, useState } from "react";
import Modal from "../../components/Modal";
import getCurrency from "../../utils/getCurrency";

const CatalogDetailModal = ({ open, openSet, data }) => {
  const [showFullDescription, showFullDescriptionSet] = useState(false);
  const [selectedImage, selectedImageSet] = useState(data?.image_link);
  useEffect(() => {
    selectedImageSet(data?.image_link);
  }, [data]);

  if (!data) return null;
  let materialsImages = data.additionalImageLinks?.split(",")?.filter(image => image !== "")
  return (
    <Modal open={open} openSet={openSet} maxWidth="md">
      <div className="p-4 flex flex-col md:flex-row gap-10">
        {/** IMAGE */}
        <div className="w-full md:w-[360px] h-[300px] md:h-[480px] rounded-md overflow-hidden">
          <img src={selectedImage} alt="catalog-image" className="size-full object-cover object-center" />
        </div>

        {/** NAME & DESCRIPTION & PRICE */}
        <div className="flex flex-col gap-3 flex-1">
          <div className="flex flex-col gap-0.5">
            <div className="text-lg font-semibold">{data.title}</div>
            <div className="text-primary/70">{data.productType}</div>
            <div className="text-lg font-semibold">{getCurrency(data.price)}</div>
          </div>
          <div className="text-primary/80 text-sm">{
            showFullDescription ?
              <>{data.description} <span className="text-primary underline cursor-pointer" onClick={() => showFullDescriptionSet(false)}>Read Less</span></> :
              data.description.length > 200 ?
                <>{data.description.substring(0, 200)} <span className="text-primary underline cursor-pointer" onClick={() => showFullDescriptionSet(true)}>Read More</span></> :
                data.description
          }</div>

          {/** MATERIALS */}
          <div className="flex flex-col gap-2">
            <div className="text-primary/60 font-semibold">Additional Images</div>
            <div className="flex flex-wrap gap-4">
              {materialsImages?.map((material, index) => {
                return (
                  <div key={index} className="size-14" onClick={() => selectedImageSet(material)}>
                    <img src={material} alt={`material-of-catalog-${index}`} className="size-full object-cover object-center" />
                  </div>
                );
              })}
            </div>
          </div>

          {/** Material */}
          {data?.material && (
            <div className="flex flex-col gap-2">
              <div className="text-primary/60 font-semibold">Material</div>
              <div className="flex flex-col gap-1 text-sm">
                {/** OVERALL */}
                <div>
                  <span>{data?.material}</span>
                </div>
              </div>
            </div>
          )}

          {/** DIMENSIONS */}
          <div className="flex flex-col gap-2">
            <div className="text-primary/60 font-semibold">Extras</div>
            <div className="flex flex-col gap-1 text-sm">
              {/** OVERALL */}
              <div>
                <span>•</span>
                <span> Size: </span>
                <span>{data?.size}</span>
              </div>
              {/** Inside SEAT */}
              <div>
                <span>•</span>
                <span> Gender: </span>
                <span>{data?.gender}</span>
              </div>
              <div>
                <span>•</span>
                <span> Brand: </span>
                <span>{data?.brand}</span>
              </div>
              {/** seatHeight */}
              {/* <div>
                <span>•</span>
                <span> Seat Height: </span>
                <span>{data?.dimensions?.seatHeight}</span>
              </div> */}
              {/** diagonalDepth */}
              {/* <div>
                <span>•</span>
                <span> Diagonal Depth: </span>
                <span>{data?.dimensions?.diagonalDepth}</span>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CatalogDetailModal;
