import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog({
    handleSubmit,
    onCloseModal,
    title,
    body,
    action
}) {
    const [open, setOpen] = React.useState(true);

    const handleClose = (e) => {
        e.stopPropagation();
        setOpen(false);
        onCloseModal()
    };

    const handleClick = (e) => {
        e.stopPropagation();
        setOpen(false);
        handleSubmit()
    }
    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='!z-[250]'
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {body}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Disagree</Button>
                    <Button onClick={handleClick} autoFocus>
                        {action || "Agree"}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
