import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./baseQuery";
import { config } from "../constants";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import { setUser } from "../store/slice/authSlice";

const nodeApi = createApi({
  baseQuery,
  reducerPath: "nodeApi",
  tagTypes: ["User", "Room", "PackageData"],
  endpoints: build => ({
    login: build.mutation({
      query: body => ({
        method: "POST",
        url: "/login",
        body,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          // const response = await queryFulfilled;c
          const token = JSON.stringify(args);
          Cookies.set("jwt_token", token);
          dispatch(setUser(args));
          toast.success("Login Successful");
        } catch (error) {
          toast.error(error.error?.data ? error.error.data?.message : config.ERROR);
        }
      },
    }),
  }),
});

export const { useLoginMutation } = nodeApi;

export default nodeApi;
