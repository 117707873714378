import { useMediaQuery } from "@mui/material";
import React from "react";
import { config } from "../../constants";

const PageWrapper = ({ children, className="" }) => {
  const isSmallScreen = useMediaQuery(config.MD_BREAKPOINT);
  return (
    <div
      className={`${
        isSmallScreen ? "w-screen px-4 py-2 mt-20 mb-16" : `${className} ml-24 py-12 pl-[136px] pr-10`
      } relative container !mx-auto max-w-screen-3xl`}
    >
      {children}
    </div>
  );
};

export default PageWrapper;
