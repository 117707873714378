import React from "react";

const Sidebar = ({ children }) => {
  return (
    <div className={`w-screen lg:bg-background-dark-gray z-10 px-4 py-2 mt-20 flex-1 md:pl-28 lg:fixed lg:h-screen lg:w-[500px] lg:mt-0 lg:py-12 lg:overflow-y-auto`}>
      {children}
    </div>
  );
};

export default Sidebar;
